@import "Colors.scss";

.draggableItem {
  &.isTouchDevice {
    -webkit-user-drag: none;
  }
  
  padding: 2px 17px 2px 9px;

  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      background: $gray11;
    }
  }

  &.isEditing {
    align-self: flex-start;
    z-index: 2;
  }
}

.lockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  align-items: center;
  padding: 0 50px;
  font-size: 15px;
  font-weight: 300;
  color: rgba(0,0,0,0.7);
}