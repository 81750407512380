
.root {
  display: flex;
  position: relative;
}

.feetInput {
  flex: 1;

  label {
    left: auto;
    right: 15px;
    text-align: right;
  }

  input {
    text-align: right;
  }

  :global(.MuiFilledInput-root) {
    border-top-right-radius: 0 !important;
  }
}

.inchesInput {
  flex: 1

  :global(.MuiTypography-root) {
    font-size: 16px;
    margin-top: 13px;
  }

  :global(.MuiFilledInput-root) {
    border-top-left-radius: 0;
    height: 53px;

    button {
      // hide because confusing... looks like it applys for both feet and inches
      display: none;
      top: calc(50% - 5px);
    }
  }

}

.dash {
  z-index: 1;
  position: absolute;
  top: 51%;
  left: calc(50% - 6px);
  pointer-events: none;
}
