.root.isEditable {
  min-width: 250px !important;
}

.input {
  font-weight: 400;

  svg:first-child {
    margin-top: 12px;
    margin-right: 5px;
  }

  div:focus {
    background: transparent !important;
  }
}

.root:not(.isEditable) {
  font-weight: 200;
  font-size: 34px;
  letter-spacing: 0.5px;
  margin-bottom: 29px;
}
