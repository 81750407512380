@import 'Colors.scss';

.divider {
  background-color: $gray2 !important;
}

#ProjectView > div.panelGroup > div.panelWrapper:not(:last-of-type) {
  margin-right: -1px;
}

