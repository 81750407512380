@import "Colors.scss";
@import "Constants.scss";

.root {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-top: 50px;
  max-width: 750px;
  margin-bottom: 30px;
  padding: 0 11px;
}

.scrollContainer {
  margin: 30px 0;
  flex: 1;
  overflow: auto;
}

.buttons {
  display: flex;
  align-items: center;

  button {
    font-size: 12px !important;
    text-transform: none !important;
    color: $primary !important;
  }
}

/* maybe belong in app */
.container {
  display: flex;
  background: $secondary;

  .linkButton {
    color: $action;
    font-weight: 500;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 10px;
  }
}

.listWithSearch {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
  background: white;
  border: 5px solid $gray11;
  border-radius: 5px;
}

.listItem.isHidden {
  opacity: 0.4;
}

.listItem+span {
  left: 8px;
  opacity: 0;
  width: 47px;
  height: 47px;
}

.list {
  background: white;
}

.listItem {
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
  height: 65px;
  //padding-left: 0 !important;
  padding-right: 6px !important;

  // dont put media hover yet, need to track active item
  &:hover {
    background-color: $gray11;

    .openProjectButton,
    .menuPopupButton {
      visibility: visible;
    }
  }

  &:global(.isPopupOpen) {
    background-color: $gray11;
  }

  :global(.MuiTypography-colorTextSecondary) {
    font-size: 13px !important;
  }
}

.openProjectButton {
  color: $action !important;
}

@media (hover: hover) {
  .menuPopupButton {
    visibility: hidden;
  }
}

.openProjectButton {
  @media (max-width: $vertical-mini-phone-max-width) {
    display: none;
  }
  visibility: hidden;
}

.clickablePart {
  flex: 1;
  display: flex;
  align-items: center;
}

.projectNameField {
  margin-left: 10px;
  flex: 1;
  display: flex;

  >div:first-child {
    flex: 1;
  }
}

.projectName {
  display: flex;
  align-items: center;
  line-height: 17px;

  > svg {
    margin-left: 12px;
    color: $gray3;
    height: 17px;
  }
}

.version {
  font-size: 14px;
  padding-top: 25px;
  display: flex;
  color: $gray3;
  justify-content: right;

  a {
    color: $gray3;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
}

:global(#create-first-project)+.version {
  display: none
}

.shareIcon:not(.isSubuserOwner) {
  color: black;
}