@import "Colors.scss";
@import "Constants.scss";

.root {
  background-color: $primary;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  color: white;
  padding: 11px;

  nav {
    max-width: 1200px; // TODO extract constant
    flex: 1;
    display: flex;
    height: 29px;
    align-items: center;
    position: relative;

    @media(max-width: $horizontal-phone-max-width) {
      width: 100vw;
      // overflow-x: hidden;
    }
  }
}

@media(max-width: $horizontal-phone-max-width) {
  .root.isRenamingProject {

    .undoRedo,
    .selectedNodeName {
      display: none;
    }
  }
}

.rootStretchWidth {
  composes: root;

  nav {
    max-width: inherit;
  }
}

.toolsButton {
  margin-right: 6px;

  svg {
    fill: $action;
  }
}

.matCardAvatar {
  flex: none;
  border-radius: 4px;
  height: 33px !important;
  width: 33px !important;
  margin-right: 10px;
  opacity: 0.9;
  border: 2px solid $action;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  svg {
    fill: $action;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media(max-width: $horizontal-phone-max-width) {
    margin-right: 0px;
  }
}

.accountMenu {
  ul>div:first-of-type {

    li,
    li:hover,
    li:active {
      background-color: $primary !important;
      pointer-events: none;
    }
  }
}

a {
  cursor: pointer;
}

.name {
  /*position: absolute;
  left: 50%;
  transform: translateX(-50%);*/

  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 18px;
  color: #eee;
  margin-top: 0;
  border-radius: 2px;
  margin-left: 10px;
  z-index: 1;

  @media(max-width: $horizontal-phone-max-width) {
    font-size: 12px;
    margin-left: 4px;
  }
}

.projectLink {
  color: #eee;
  text-decoration: none;
}

.author {
  margin-top: 0;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;

  >span {
    opacity: 0.4;
  }

  a {
    margin-left: 3px;
    opacity: 0.9;
    color: #ccc;
    text-decoration: none;
  }
}

.pageLogo {
  //filter: hue-rotate(-13deg);
  color: $action;
}

.button {
  color: #6d767f !important;
}

button.backButton {
  // commment out to keep button at the right
  //position: absolute !important;
  //background: $primary !important;
  //margin-left: -5px !important;
  color: $action !important;
  //border: none !important;
  margin-right: 20px !important;
}

/*.backButton {
  color: white !important;
  margin-left: -10px !important;
  margin-right: 10px !important;
}*/

.actionButton {
  color: $action !important;
  font-size: 12px !important;
  //text-transform: none !important;
  margin-right: 10px !important;

  @media(max-width: $horizontal-phone-max-width) {
    margin-right: 0 !important;
  }
}

.upgradeButton {
  background-color: #ff8223;
  color: black;
  margin-right: 30px;

  &:hover {
    background-color: darken(#ff8223, 10%);
  }
}

.undoRedo {
  margin-left: 10px;
  margin-right: 30px;
  white-space: nowrap;

  @media(max-width: $horizontal-phone-max-width) {
    margin-right: 10px;
  }
}

.centeredContent {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.projectImage {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px;
  opacity: 0.8;
}

.devBanner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  font-size: 9px;
  background-color: $orange;
  color: white;
  -webkit-font-smoothing: subpixel-antialiased;
  text-transform: uppercase;
}

.logo {
  display: flex;
  align-items: center;

  img {
    height: 26px;
  }
}

.appName {
  position: relative;
  margin: 2px 0px 0 7px;
  color: white;
  font-family: "renogareregular";
  font-size: 15px;
  display: flex;
  align-items: flex-end;
}

.version {
  text-transform: uppercase;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 7px;
  font-family: Roboto;
  font-weight: 500;
  color: $primary;
  text-align: right;
  margin-top: 1px;
  margin-left: 8px;

  background-color: $action;
  font-weight: bold;

  &.isProduction {
    background: white;
    font-weight: normal;
  }

  @media(max-width: $horizontal-phone-max-width) {
    display: none;
  }
}

.sendEstimateButton {
  margin-right: 3px !important;
}

@media(max-width: $horizontal-phone-max-width) {
  .sendEstimateButton .buttonText {
    display: none;
  }
}

.selectedNodePath {
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: 18px;

  @media(max-width: $horizontal-phone-max-width) {
    .ancestor {
      display: none;
    }
  }
}

.reportNav {
  button {
    color: $action !important;
  }
}


.ancestor {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  color: $secondary;
  opacity: 0.85;
  font-weight: 400;
}

.ancestor {
  &::after {
    cursor: auto;
    content: "/";
    display: inline-block;
    font-weight: 300;
    opacity: 0.5;
    padding: 0 8px;

    @media(max-width: $horizontal-phone-max-width) {
      padding: 0 2px;
    }
  }
}

.selectedNodeName {
  font-weight: 600;
  font-size: 24px;
  white-space: nowrap;

  @media(max-width: $horizontal-phone-max-width) {
    font-size: 13px;
    white-space: normal;
  }
}

.projectNameField a,
.projectNameFieldInput {
  color: white !important;
}

.projectNameField {
  z-index: 1;
  padding: 3px;

  &.isEditable:hover {
    background: rgba($highlight, 0.2);
  }
}

.projectNameFieldInput:hover {
  background-color: transparent !important;
}

.projectNameFieldEdit {
  >div:first-child {
    // bad
    margin-top: -11px;
  }

  label {
    color: $action;
  }
}

.copyIcon {
  margin-left: 10px;
  color: white;
  visibility: hidden;
}

.name:hover {
  .copyIcon {
    visibility: visible;
  }
}

.listAvatar {
  border-radius: 50%;
}

.listAccountName {
  color: $gray13;
}