@import 'Colors.scss';

.root {
    background-color: rgba($primary, 0.9);
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .icon {
        color: #ff8223;
    }

    .button {
        background-color: #ff8223;
        color: black;
        margin-right: 10px;

        &:hover {
            background-color: darken(#ff8223, 10%);
        }
    }

    >div {
        margin: 0 20px;
    }
}