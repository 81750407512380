@import "Colors.scss";

.root {
  //max-width: 1000px;
  margin-right: -10px;
  margin-left: -10px;
  //width: auto;

  :global(.providingItem-unit) {
    display: none;
  }

  // duplicate 
  h2,
  h3 {
    color: $gray5;
  }

  h2 {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 12px;
    letter-spacing: 0.4px;
    font-weight: 400;  
    line-height: normal;
  }

}

.item {
  width: 100%;
}

.newPrice {
  text-align: right;
}

.arrowIcon {
  padding: 6px 0 0 0;
}

.row {
  >td {
    vertical-align: middle;
  }
}

.checkbox {
  padding: 0;
}

.options {
  width: 0;
  padding: 0;
  position: relative;

  .hoverToolsRight {
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    background: white;
  }
}

.hoverToolsRight {}

.row:hover .hoverToolsRight {
  visibility: visible;
}

.badNewsSection {
  margin-top: 30px;
  padding: 20px 0px;
  background-color: $gray11;

  .message {
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    svg {
      margin-right: 10px;
    }
  }

  tr:hover {
    background-color: rgba(black, 0.02);
  }
}

.providingItem {
  cursor: auto !important;
}
