@import "Colors.scss";

.root {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  outline-offset: -1px;

  &.isDragging {
    outline: 1px dashed $action;
  }

  &.isDraggingOver {
    &::after {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $action;
      opacity: 0.6;
    }
    > * {
      pointer-events: none;
    }
  }


}
