@import "Colors.scss";

.root {

  :global(.MuiPaper-root) {
    max-height: 500px;
    max-width: 800px;
  }

  pre {
    user-select: text;
  }

  a {
    color: $action !important;
    white-space: nowrap;
  }

  h1 {
    margin-top: 10px;
    line-height: 1.5;
  }

  .errorIcon {
    color: $red;
  }

  .button {
    background-color: #cf5c19;
  }

  .title {
    > * {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: 10px;
      }
    }
  }
}
