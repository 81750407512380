@import "Colors.scss";
@import "Constants.scss";

.report {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: $gray14;
}

.reportPanel {

  // bad hack to hide report tabs on phone
  >div>div:first-child {
    min-height: auto;
  }
}

.progressContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-bottom: 180px;
}

.colorButton {
  margin-right: 9px;
}

.progressBar {
  width: 100px;
  margin-top: 23px;
}

.button {
  color: $primary !important;
}

.pdf,
.page {
  flex: 1;
}

.customHtmlMarginTopField {
  width: 250px;
}

.page {
  font-family: Montserrat;

  overflow: hidden;
  border-radius: 1px !important;
  margin: 15px;
  padding: 55px;
  font-size: calc(var(--report-font-size-scale) * 16px);
  position: relative;
}

.firstPage {
  padding-top: 220px;
}

.test {
  font-family: "Seaweed Script";
}

.pageContent,
.reportContent>div {
  display: flex;
  flex-direction: column;
}

.pageContent {
  // manually display when needed because slow
  padding: 30px 75px;
}

.page.printablePage {
  padding: 243px 74px 74px;

  div,
  span {
    font-family: Montserrat;
  }
}

.tabContent {
  overflow-y: auto;
  display: flex;
  flex: 1;

  @media (min-width: $horizontal-phone-max-width+1) {
    justify-content: center;
  }
}

.navContainer {
  display: flex;
  flex: 1;
}

.reportPreparation {
  max-width: 1200px;
}

.scrollContainer {
  flex: 1;
  overflow: auto;
}

.hiddenOutside {
  position: absolute;
  top: 9999px;
  left: 9999px;
}

.hidden {
  visibility: hidden;
  pointer-events: none;
}

.preview[hidden],
.reportPreparation[hidden] {
  display: none;
}

.preview {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.previewTools {
  background: $gray11;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
}

.header {
  >div {
    // todo enable border bottom for pages 2+
    //    padding-bottom: 10px;
    //    border-bottom: 1px solid blue;
  }
}

.firstPageInfo {
  position: relative;
  display: flex;
  background-color: var(--logo-color-pale);
  //margin: 0px -46px; // when using 2 separate sections
  margin: 50px -99px 30px;
  padding: 30px 99px;

  .columnOne {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }

  .columnTwo {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.reportType {
  display: flex;
  align-items: center;
}

.separator {
  flex: 1;
}

.page:not(.isEditable) {
  .firstPageInfo {
    margin-top: 5px;
  }

  .reportProperties {
    color: black;
    margin-bottom: 20px;
    font-size: calc(var(--report-font-size-scale) * 17px);

    >div:nth-of-type(1) {
      white-space: nowrap;
    }

    >div:nth-of-type(2) {
      font-size: calc(var(--report-font-size-scale) * 15px);
      border-top: 1px solid #bcbec0;
      margin-top: 2px;
      padding-top: 7px;
    }
  }
}

.page.isEditable {
  .columnOne {
    width: 50%;
  }

  @media (max-width: $vertical-phone-max-width) {
    .columnOne {
      width: 100%;
    }

    padding: 55px 8px;
    margin: 15px 0;

    .firstPageInfo {
      flex-direction: column;

      .columnTwo {
        width: 100%;
        align-items: stretch;
      }
    }

    &.firstPage {
      //padding: 0;
    }
  }
}

.twoColumns {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.reportElement {
  margin-top: 10px;
}

// not the cleanest
.total>table {
  margin-bottom: 0px !important;
}

.bottomButton {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.preparedByEditable {
  width: 50%;
}

.hasNodesExcludedFromReports {
  display: flex;
  align-items: center;
  padding: 15px 0px 20px;

  >svg {
    margin-right: 8px
  }
}

.paid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-35deg);
  pointer-events: none;
  font-size: 40px;
  color: $red;
  text-transform: uppercase;
  border-top: 2px solid $red;
  padding-top: 6px;
  border-bottom: 2px solid $red;
  padding-bottom: 6px;
}

.introText {
  margin-top: 0;
  margin-bottom: 30px;

  :global(.ql-container) {
    font-size: calc(var(--report-font-size-scale) * 14px) !important;
  }
}

.mobileScroller {
  max-width: 90vw;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  > table {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: $vertical-phone-max-width) {
    align-items: flex-start;
  } 
}