@import 'Colors.scss';

.root {
  background: $gray11;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 8px;
  border-bottom: 1px solid  $gray4;
  flex-shrink: 0;
  margin-bottom: 3px;
}

.row {
  display: flex;
  flex: 1;
}

.search {
  background:  $secondary;
  padding: 0;
  padding-left: 10px;
  border-radius: 24px;
  flex: 1 1;
  display: flex;
  align-items: center;
}

.addButton {
  margin-left: 10px;
  color: $action;
}

.searchIcon {
  margin-top: 3px;
  margin-right: 4px;
  color: $gray3;
}

.funnelIcon {
  border-color: rgb(19, 39, 54);
  padding: 5px;
  margin: 0 -4px 0 2px;
}

.formControl {
  flex-direction: row;
  margin-top: -15px;
}

.filters {
  display: flex;
  width: 100%;
  align-items: center;

  > div {
    flex: 1;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }
}

.category {
  margin-right: 10px !important;

  > label[data-shrink=true] {
    transform: scale(0) !important;
  }
}

.clearFilters {
  font-weight: 400 !important;
  font-size: 12px !important;
  padding-bottom: 4px !important;
}

.funnelRemove, .clearIcon {
  color: #f48500 !important;
}

.textField {
  flex: 1;
}

.inputRoot {
  width: 100%;
  height: 32px;
  background-color: transparent !important;
}

.inputInput {
  padding: 0;
}

.optionsButton {
  flex: 0 !important;
  margin-right: -5px;
  margin-left: 4px;
}