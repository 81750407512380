@import "Colors.scss";

.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  height: 100%;
}

.title {
  color: $gray3;
  letter-spacing: 0.5px;
  font-size: 13px;
}

.row {
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 0 !important;
}

.list {
  background: white;

  .item {
    overflow: hidden;
  }

  >div,
  >div>div {
    outline: none;
  }
}

.nav {
  background: $gray11;
  display: flex;
  align-items: center;
  padding: 6px 8px 0;

  >div {
    flex: 1;
  }

  button {
    flex: 1 1 auto;
    white-space: nowrap;
    font-weight: 400;

    svg {
      margin-right: 5px;
    }
  }
}

.addButton {
  //border-radius: 0 !important;
  color: $action !important;

  div {
    display: flex;
    align-items: center;
  }
}

.image {
  width: 60px !important;
  height: 60px !important;
}

.root:hover .fabButton {
  opacity: 1;
}

.priceUpdateMessage {
  display: flex;
  align-items: center;
  padding: 2px 6px 0 8px;
  font-size: 13px;
  background: #fafafb;

  >svg {
    margin-right: 10px;
  }

  >div {
    flex: 1;
  }

  button {
    font-weight: 400;
    font-size: 13px;
    color: $action;
    text-transform: none;
    margin-left: 5px;
    margin-right: 2px;
  }
}

.priceUpdateProgressContainer {
  width: 50px;
  height: 10px;
  flex: none !important;
  margin-right: 15px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.priceUpdateProgressBar {
  width: 50px;
  height: 10px;
  position: absolute;
}

.updatePercent {
  left: 3px;
  top: 0px;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  color: white;
}