@import "Colors.scss";
@import "Constants.scss";

// duplicate
.root {
  min-width: 400px;
  display: flex;
  flex-direction: column;
}

.title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 6px 14px !important;
  background: $primary;
  color: white !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .isEmptyTitle {
    label:not([data-shrink="true"]) {
      color: white !important;
      font-size: 24px;
    }
  }
  @media (max-width: $vertical-phone-max-width) {
    padding: 6px 0px !important;
  }
}

.nameField,
.categoryCombo {
  flex: 1;
  margin-left: 10px !important;
  margin-right: 10px !important;

  label {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  input {
    color: white;
  }
}

.nameField > div,
.categoryCombo > div > div {
  background-color: #ffffff14 !important;
  font-weight: 500;
  font-size: 20px !important;
}

.categoryCombo {
  max-width: 25%;
  flex: none;
  display: flex;
  
  @media (max-width: $vertical-phone-max-width) {
    flex: 1;
    margin-left: 0 !important;
  }

  > div {
    margin: 0;
  }

  :global(.MuiFilledInput-input) {
    padding: 8px 7px;
    span {
      color: white;
      font-size: 20px;
      font-weight: 500;

      @media (max-width: $vertical-phone-max-width) {
        font-size: 16px;
      }
    }
  }
}

.content {
  flex: 1;
  display: flex;
  padding: 15px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: $vertical-phone-max-width) {
    flex-direction: column;
  }
}

.column {
  min-width: 350px;

  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 10px;
  }
}

.imageBox {
  margin-left: 20px;
  min-height: 100px;
}

.column2 {
  padding: 15px;

  z-index: 2;
  margin: -15px -15px 0 0;
  background: $gray17;
  position: relative;

  @media (max-width: $vertical-phone-max-width) {
    margin: 0;
    margin-top: 20px;
  }
}

.textField {
  flex: 1;

  :global(.Mui-focused) .lockIcon {
    visibility: hidden;
    pointer-events: none;
  }
}

.lockIcon {
  margin: 13px -14px 0 -6px !important;

  &[hidden] {
    display: block !important;
    visibility: hidden !important;
  }
}

.textFieldPrice {
  flex: 1;
  input {
    font-weight: 500;
  }
}

.nameField {
  flex: 1;
  margin-left: 10px !important;
  margin-right: 10px !important;
  > div {
    background-color: #ffffff14 !important;
    font-weight: 500;
    font-size: 20px !important;
  }
  label {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  input {
    color: white;
  }

  @media (max-width: $vertical-phone-max-width) {
    flex: 2;
  }
}

.descriptionField {
  width: 100%;

  > div {
    font-size: 14px !important;
  }
}

.formRow {
  display: flex;
  align-items: center;

  > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.dividedBy {
  text-align: center;
  flex: 0;
  vertical-align: baseline;
}

.priceAndUpdatedDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > .updatedDate {
    font-size: 10px;
    margin-bottom: 3px;
    color: $gray3;
  }

  &.isDynamicPrice {
    align-items: stretch;
  }
}

.priceAndUnit {
  margin-top: -5px;
  display: flex;
  align-items: flex-start;
}

.unit {
  color: #6d6f70;
  font-size: 13px;
}

.packRow {
  display: flex;
  align-items: baseline;
  margin-top: -15px;
  font-size: 15px;
}

.textFieldPackCount {
  > input {
    font-weight: 500;
  }
}

.checkboxPack {
}

.groupTitle {
  color: $gray5;
  font-size: 0.75rem;
  margin-top: 25px;
}

.groupTitleQuantity {
  margin-top: 5px !important;
  margin-bottom: -12px;
}

.smallerButton {
  width: 0.8em !important;
  height: 0.8em !important;
}

.wasteSettingsButton {
  flex: 0;
  color: $primary !important;
}

.openMerchantUrlLink {
  flex: 0 0;
}

.moreButton {
  color: white !important;
  margin-right: -11px !important;
}

.for {
  flex: none !important;
  padding: 25px 10px;
  text-align: center;
  font-style: italic;
  font-weight: 300;
}

.isMaterial .for {
  padding: 25px 20px;
}

.formIcon {
  margin-right: 14px !important;
  margin-top: 20px;
  align-self: flex-start;
  flex: none;
  color: rgba($primary, 0.85);
}

.priceIcon {
  margin-top: 26px;
  align-self: flex-start;
}

.priceAndHourlyRate {
  display: flex;
  flex-direction: column;
}

.confirmDeleteButton {
  color: $red !important;
}

.hourlyRateAndTimeNeeded {
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 5px;
  }
}

.hourlyRateInput {
  flex: 1;
}

.hourlyRateLink {
  display: flex;
  align-items: center;
  color: $primary;

  span {
    margin-left: 5px;
    // duplicate
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.priceAdjustmentText {
  font-size: 12px;
  display: inline-block;
  padding-left: 8px;
}

// duplicate
.enterQuantityLink {
  margin-top: 10px;

  font-size: 13px;
  //color: $action;
  opacity: 0.7;
  text-decoration: underline;
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
}

// duplicate
.expansionPanel {
  font-size: 13px;
  font-weight: 400;
  color: #9a9a9a;
}
// duplicate
.expansionPanelContent {
  padding: 0;
  margin-left: 0px;
  margin-bottom: 10px;

  .unitComboBox {
    flex: inherit;
    width: auto;
  }
}

// specific
.expansionPanelContent {
  display: flex;
  flex-direction: column;
}
