@import 'Colors.scss';

.root {

}

// duplicate


.tabContent:not([hidden]) {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
}

.tab:not(.isEmpty) {
  font-style: italic;
  font-weight: bold;
}

.tab {
  pointer-events: all !important;
}

.tabContent {
  :global(.Draftail-Toolbar),
  :global(.ql-toolbar) {
    background-color: $gray11;
  }
}

.tabs {
  font-size: 11px !important;

  :global(.Mui-selected) .itemsBadge > span {
    background-color: $action;
  }
}
