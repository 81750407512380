@import 'Colors.scss';

.root {
  margin: 10px 0;

  li {
    display: flex;
    align-items: center;

    svg {
      width: 18px;
      height: 18px;
      color: $action;
      margin-right: 6px;
    }
  }

  .redCross {
    color: $red;
  }
}
