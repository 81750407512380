@import 'Colors.scss';

.nav {
  background-color: $gray11;
 // flex-direction: column;
//  height: auto !important;
}

.button {
  min-height: 59px;
  transform: scale(1);
  //transition: transform 0.2s;
  &:hover {
    //transform: scale(1.1);
  }

  &.disabled {
    opacity: 0.25;
  }
}

.tooltip {
  margin-right: -10px;
  margin-top: -10px;
}

.menu {
  transform: translateX(4px) !important;
}