.root {

  > div {
    display: flex;
    align-items: center;
  }

  .checkbox {
    margin: 0;
    margin-left: -10px;
    margin-right: 10px;
  }

  .label {
    line-height: 1.2;
    font-size: 15px;
  }

  .infoButton {
    visibility: hidden;
  }

  > div:hover .infoButton {
    visibility: visible;
  }
}
