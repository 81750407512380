@import "Colors.scss";

.DimensionLine {
  .default, .edgeLine {
    stroke: rgba(0,0,0,.6);
  }

  .text {
    font-family: Roboto;
    fill: $primary;
  }

  .textBackground {
    fill: white;
    opacity: 0.9;
  }

  // old way
  .foreignContainer {
    display: flex;
    justify-content: center;
  }

  .textField {
    pointer-events: none;
    z-index: 0; // buggy if we don't put this
    background: #ffffffe8;
  }

  .textFieldNoLabel {
    input {
      padding: 8px 8px 6px;
    }
    label {
      display: none !important;
    }
  }
}
