.root {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: top;

  > div {
    font-size: calc(var(--report-font-size-scale) * 17px);
    font-weight: 200;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}

:global(.printablePage) .root {
  margin-top: 60px;
}

.richText {
  margin-top: 0 !important;
  margin-bottom: 30px !important;

  & > div {
    font-size: calc(var(--report-font-size-scale) * 13px) !important;
  }
}
