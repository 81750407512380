@import "Colors.scss";

.root {
  height: 65px;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  background-color: $gray17 !important;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid white;
  padding: 0 0 0 10px;

  @media (hover: hover) {
    &:hover {
      .hoverButtonsLeft {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  //@media (hover: hover) {
  &:hover {

    .hoverButtonsRight,
    .button {
      visibility: visible;
      opacity: 1;
    }
  }

  //}

  .addButton {
    visibility: visible;
  }

  &.hasItemsChecked .hoverButtonsLeft {
    visibility: visible;
    opacity: 1;
  }

  &.hasNoTasks {
    cursor: default !important;
  }

  &.hasOnlyOneCategory {
    padding-right: 0 !important;
    cursor: default !important;

    .hoverButtonsLeft {
      left: 10px;
    }
  }

  .hoverButtonsLeft,
  .hoverButtonsRight {
    position: absolute !important;

    background-color: $gray12;
    z-index: 1;
  }

  .hoverButtonsLeft {
    left: 22px;
    background-color: $gray11;
    padding: 0 6px;
    height: 50px;
    display: flex;
    align-items: center;

    //@media (hover: hover) {
    //visibility: hidden;
    opacity: 0.01 //}
  }

  .hoverButtonsRight {
    left: auto;
    right: 0;
    margin-right: 4px !important;
    justify-content: flex-end;
    display: flex;

    // @media (hover: hover) {
    visibility: hidden;
    // }
  }
}

.rootEdit {
  display: flex;
  align-items: center;
}

.editButtons {
  margin-left: 10px;
}

.buttonWithInstructions {
  margin-right: 40px !important;
  visibility: visible !important;
}

.isDraggingBigBorder {
  outline-width: 3px !important;
  outline-offset: -3px !important;
}

.expandButton {
  width: 24px;
  height: 24 px;
  margin-left: -10px;
  transition: transform 0.3s !important;
  opacity: 0.8;

  transform: scale(0.8) rotate(-90deg);

  &.isExpanded {
    transform: scale(0.8) rotate(0deg);
  }

  * {
    pointer-events: none;
  }
}

.checkbox {}

.name {
  padding: 5px;
  margin-left: 7px;
  text-transform: uppercase;
  font-size: 12px;
  color: $gray3;
  cursor: auto;
}

.details {
  margin-left: 10px;
  margin-right: 10px;
  color: $gray3;
  font-size: 13px;

  // duplicate
  text-align: right;
  min-width: 90px;
}

.detailsNumberOfTasks {
  opacity: 0.9;

  &::after {
    content: "-";
    margin: 0 8px;
  }
}

// duplicate
.button {
  //@media (hover: hover) {
  visibility: hidden;
  //}

  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    margin-right: 5px !important;
  }

  &.editButton {
    color: $gray3 !important;
    margin-left: 5px;
  }
}

.nameDropdown {
  width: 200px;
}

.acceptButtonIcon {
  color: $green !important;
}

.cancelButtonIcon {
  color: $red !important;
}