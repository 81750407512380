.root {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: top;

  > div {
    font-size: calc(var(--report-font-size-scale) * 17px);
    font-weight: 200;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }
}

.richText {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
}

.image {
  max-height: 500px;
  max-width: 90%;
  margin-bottom: 50px;
}
