@import 'Colors.scss';

.root {
  &.isDisabled {
    pointer-events: none;

    input {
      pointer-events: none;
    }

    //margin-left: -12px !important;
  }
}