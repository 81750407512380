.root {
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  flex: 1; 
}

.isDrawingTree :global(.ant-tree-list-holder) {
  margin-left: -20px;
}


.root:hover .fabButton {
  opacity: 1;
}
