@import "Colors.scss";
@import "Constants.scss";

.root {
  width: 1000px;

  @media(max-width: $vertical-phone-max-width) {
    width: calc(100vw - 50px) !important;
  }
  height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;

  &.isSatelliteTabSelected {
    width: 500px;
  }
}

.addressField {
  width: 400px;
  @media(max-width: $vertical-phone-max-width) {
    width: 60vw;
  } 
}

.tabContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 609px;
  width: 100%;
}

.tabs {
  flex: none;
  margin: -8px -24px 0;
  background-color: $secondary;
  align-self: stretch;
}

.subtitle {
  margin-top: 25px;
  font-size: 14px;
  color: $text;
}

.autocomplete {
  margin-top: 20px;
  display: flex;
  align-items: center;

  >*:first-child {
    margin-right: 10px;
  }
}

.upload {
  margin-top: 20px;
}

.existingImages {
  width: 100%;
  overflow: auto;
  flex: 1;
}

.searchToolbar {
  background: transparent;
  padding: 0;
  margin: 25px 0 10px;
  align-self: stretch;
}

.documentName {
  margin-left: 5px;
  display: flex;
  align-items: center;

  >button {
    @media (hover: hover) {
      visibility: hidden;
    }
  }

  @media (hover: hover) {
    &:hover {
      >button {
        visibility: visible;
      }
    }
  }
}

.uploadButtons {
  cursor: pointer;

  >div {
    display: flex;
    align-items: center;
  }
}

.progressBar {
  width: 300px;
}

.imagesList {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.logoImageContainer {
  position: relative;
  margin: 5px 5px;
  cursor: pointer;
  width: calc(50% - 10px);
  @media(max-width: $vertical-phone-max-width) {
    width: 90%;
  }

  &.isEmpty>div:hover {
    background: transparent !important;
  }

  > div {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .imageButtons {
    width: 100%;
    position: absolute;
    right: 10px;
    bottom: 10px;
    height: 40px;
    display: flex;
    align-items: center;

    >* {
      visibility: hidden;
      margin-left: 30px;
      color: $primary;

      &:hover {
        color: $gray15;
      }
    }

    svg {
      width: 2em;
      height: 2em;
      stroke: white;
      stroke-width: 0.5px;
      stroke-opacity: 0.5;
    }
  }

  .name {
    flex: 1;
    display: flex;
    margin-left: 15px;

    &:not(.isEmpty) {
      visibility: visible;
      margin-left: 15px;
      font-size: 15px;
      font-weight: bold;
      color: $primary;

      >div {
        background-color: $gray12;

        &:hover {
          background-color: $gray8;
        }
      }
    }
  }

  .nameDisplayComponent {
    display: flex;
  }

  &.isSelected {
    outline: 3px solid $action;

    .imageButtons>span {
      visibility: visible;
      color: $action !important;
    }
  }
}

.logoImageContainer:hover .imageButtons>* {
  visibility: visible;
}

.satelliteImage {
  overflow: hidden;
  width: 480px;
  height: 480px;

  position: relative;

  img,
  .grid {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .grid {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%230000008a' %3E%3Cpath d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: left -6px top -6px;
    opacity: 0.5;
  }

  >svg {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0.7;
  }
}

// duplicate
.button {
  margin: auto;
  align-self: center;

  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    margin-right: 10px !important;
  }
}

.canSelectMultiplePages {
  flex: 1;

  .text {
    color: $gray5;
  }
}