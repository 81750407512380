@import 'Colors.scss';

.root {
  display: flex;
  align-items: center;
}

//duplicate
.editTools {
  z-index: 1;
  //width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-bottom: 6px;
  margin-top: -3px;
  margin-right: 3px;
}

.detailsButton {
  color: $primary !important;
  //color: $action !important;
}

.acceptButtonIcon {
  color: $green !important;
}

.cancelButtonIcon  {
  color: $red !important;
}

.flexSeparator {
  //flex: 1;
  width: 10px;
}

.rootEdit {
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;

  > div {
    background: white; // shouldn't bee needed
    display: flex;
    width: 100%;
  }
}

.isHidden {
  opacity: 0.4;
}

// duplicate
.verifiedIcon {
  color: $action;
  width: 19px;
  height: 13px;
}
