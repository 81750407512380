@import "Colors.scss";
@import "Constants.scss";

.root {
  flex: 1;
  display: flex;
  min-height: 0; // chrome 72 bug https://www.reddit.com/r/webdev/comments/alg8az/anyone_experiencing_issues_with_flexbox_and/

  @media(max-width: $vertical-phone-max-width) {
    flex-direction: column;
  }
}

.helpSnackBar {
  &.isTouchDevice {    
    @media(max-width: $vertical-phone-max-width) {
      bottom: 20px !important;
    }
  }

  >div {
    background-color: $highlight;
    color: $text;
  }

  &.isCalibration>div {
    background-color: $orange;
  }

  .helpMessage {
    display: flex;
  }

  .closeButton {
    color: $gray11;
    margin-left: 10px;
  }

  .cancelButton {
    
  }
}

.flexColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.scrollContainer {
  position: relative;
  flex: 1;
  outline: none;
}

.backgroundGrid {
  pointer-events: none;
}

.svgHider {
  position: relative;
  overflow: visible;
  width: 0px;
  height: 0px;
}

.safariForeignElements {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  overflow: hidden;

  >div {
    position: absolute;
  }
}

:global(.safariForeignContainer) {
  position: absolute;
}

.svgTag {
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23e0e1e2' fill-opacity='0.4'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  //background-position: left -6px top -6px;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  will-change: transform;
  backface-visibility: hidden;
}

.arrowHead {
  fill: rgba(0, 0, 0, 0.5);
}

.isDrawing {
  cursor: -webkit-image-set(url("/images/crosshair.svg") 1x, url("/images/crosshair@2x.svg") 2x) 48 48, pointer;
}

.isZooming,
.hasALotOfElements {
  text {
    display: none;
  }

  :global(.DimensionLine):not(:global(.isEditMode)) {
    display: none;
  }

  :global(.DimensionSurface) {
    display: none;
  }
}