@import "Colors.scss";

.root {
  color: $gray5;
  opacity: 0.8;
  display: flex;
  align-items: center;

  > svg {
    //  width: 0.8em;
    //  height: 0.8em;
  }
}
