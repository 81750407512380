@import "Colors.scss";

.root {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px;

    .button {

        position: absolute;
        right: 10px;
        top: 5px;
        border: 2px solid $primary;
        border-radius: 10px;
    }

    .tabs {
        flex: none !important;

        button {
            color: $primary;
        }
    }

    .button {
        color: $primary !important;
    }
}