@import "Colors.scss";

.root {
  min-width: 320px;
}

.tabContent:not([hidden]) {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.tabs {
  font-size: 11px !important;

  :global(.Mui-selected) .itemsBadge > span {
    background-color: $action;
  }
}

.inProgressBadge > span {
  background-color: $action;
}

.itemsBadge > span {
  top: -2px;
  right: -5px;

  color: white;
  background-color: $gray5;
}

.selectionActions {
  flex: 1;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }

  .buttons {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;

    // duplicate
    button {
      color: $action !important;
      font-size: 12px !important;
      text-transform: none !important;

      svg {
        // margin-right: 5px !important;
      }
    }

    .archiveButton {
      color: $primary !important;
    }

    .deleteButton {
      color: $red !important;
    }

    .cancelButton {
      color: $text !important;
    }
  }

  .moreButton button {
    color: $text !important;
  }
}
