
.root {
  max-width: 900px;
}

.subheader {
  font-family: Roboto;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 11px;
  font-weight: 500;
}