@import "Colors.scss";

.root {
  width: 100%;
  position: relative;

  @media (hover: hover) {
    &:hover > .dragHandle {
      visibility: visible;
    }
  }
}

.dragHandle {
  @media (hover: hover) {
    visibility: hidden;
  }
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  left: 1px;
  width: 11px;
  color: $gray8;
  z-index: 3;

  > svg {
    margin-left: -5px;
    width: 20px;
  }
}
