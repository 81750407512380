@import "Colors.scss";

.root {
  max-height: 500px;

  pre {
    user-select: text;
    white-space: pre-wrap;
    max-width: 50vw;
  }

  a {
    color: $action !important;
  }

  h1 {
    margin-top: 10px;
    line-height: 1.5;
  }

  .errorIcon {
    color: $red;
  }

  .title {
    > * {
      display: flex;
      align-items: center;

      > *:first-child {
        margin-right: 10px;
      }
    }
  }
}
