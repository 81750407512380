@import 'Colors.scss';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  background: $primary;
  color: white;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.6;
}

.content {
  flex: 1;
  min-height: 1px;
}

.panelTitle {
  display: flex;
  align-items: center;
  > svg {
    margin-right: 6px;
    padding-bottom: 4px;
  }

}