@import "Colors.scss";

.root {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    padding-right: 5px;
    width: 25%;
  }
}

.lastItem {
  display: flex;
  align-items: center;

  > *:first-child {
    flex: 1;
  }
}

.textField {
  min-width: 150px;
}

.excelButton {
  width: 46px;
  height: 46px;
  margin-left: 4px;
  position: relative;

  svg {
    width: 36px;
    height: 36px;
    color: #1d6f42;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 5px;

  > .label {
    white-space: nowrap;
    margin-right: 10px;
    font-size: 14px;
  }

  .switch {
    flex: none;
  }

  .dateInput {
    width: 25%;
  }
}