@import 'Colors.scss';
@import 'Constants.scss';

.root {}

.input {
  display: flex;
  height: auto;
  padding: 8px 7px;
  padding-right: 2px;
}

.textField {
  width: 100%;

  >div {
    // height: 56px;
  }
}

.chip {
  background-color: $gray10;
}

.valueContainer {
  min-height: 32px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  position: relative;
  overflow: hidden;

  &>.singleValue+div {
    position: absolute;
    bottom: -3px;
    left: -2px;
  }

  &>p {
    position: absolute;
    bottom: -3px;
    left: 4px;
    font-size: 15px;
    width: 100%;
  }

  .icon {
    display: none;
  }

  >.singleValue {
    margin-top: 12px;
    margin-left: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .count {
    display: none;
  }
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 8px;
  flex: 1;
}

.count {
  flex: 1;
  text-align: right;
  font-size: 10px;
  opacity: 0.5;
}

.placeholder {
  position: absolute;
  left: 4px;
  bottom: 0px;
  font-size: 16px;
  opacity: 0.8;
}

$menuButtonsHeight: 52px;

.paper.isCreateable {
  position: relative !important;

  >div:first-child {
    padding-top: 0;
  }
}

.portal {}

.option {
  width: 100%;
  display: flex;
  align-items: center;

  >div:first-child:not(:empty) {
    margin-right: 6px;
  }
}

.addMissingOption {
  white-space: nowrap;
  height: 52px;
  display: flex;
  //flex-direction: column;
  align-items: center;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  background: $highlight;


  b {
    display: block;
    margin-left: 5px;
    font-weight: 600;
  }
}

.createItem {
  margin-top: 10px;
  display: block;
  font-size: 13px;
  color: $action;
  text-decoration: underline;
  cursor: pointer;
}

.paddedContainer {
  padding-bottom: $menuButtonsHeight;
}

.buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $menuButtonsHeight;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f0f1;
  background-color: #fafafb;
  padding: 4px 8px;
  display: flex;
}

// duplicate
.button {
  height: 34px;
  //width: 100%;
  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;
  border: none;

  svg {
    margin-right: 5px !important;
  }
}

.root.isDisabled {
  pointer-events: none;

  button {
    display: none !important;
  }
}


.menuItemButtons {}