.root.isEditable {
  display: flex;
  flex-direction: column;
}

.root:not(.isEditable) {
  position: relative; // ?
}

.content {
  border-top: 1px solid #BCBEC0;
  margin-top: 2px;
  padding-top: 6px;
  line-height: 1.2;
}

.firstLine {
  font-weight: 500 !important;
  font-family: MontserratMedium, Montserrat !important;
}

.address {
  white-space: pre-wrap;
}