
.root {

}

.extraButtons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.excelButton {
  width: 46px;
  height: 46px;
  margin-left: 4px;
  position: relative;

  svg {
    width: 36px;
    height: 36px;
    color: #1d6f42;
  }
}