.rowHoverButtons {
  border-bottom: 1px solid #85929e;

  > * {
    visibility: hidden;
  }
}

tr:hover .rowHoverButtons {
  > * {
    visibility: visible;
  }
}
