@import 'Colors.scss';

.scrollContainer {
  overflow: auto;
  flex: 1;
}

.button {
  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    margin-right: 5px !important;
  }
}

.progress {
  display: flex;
  align-items: center;
}

.hideable {
  overflow: hidden !important;
}

.isFullscreen {
  transition: flex 0.3s ease-in-out;
}