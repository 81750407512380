@import "Colors.scss";

.root {}

.main {
  overflow: hidden;
  border-top: 1px solid #f0f0f1;
  display: flex;
  align-items: center;
  background: $secondary;
  color: #707075;
  font-size: 12px;
  font-weight: 400;

  .subTotal {
    padding-right: 30px;
  }

  .grandTotal {
    cursor: pointer;
    padding-left: 30px;
    color: $text;
    flex: 1;
    //justify-content: flex-end;

    font-weight: 400;

    >div:last-child {
      font-weight: 500;
      font-size: 14px;
      //border-bottom: 3px double black;
    }
  }

  .settingsButton {
    margin: -9px;
    margin-right: 0;
    visibility: hidden;
  }

  &:hover .settingsButton {
    visibility: visible;
  }

  .footerButton {
    display: flex;
    padding: 7px 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    &:not(.subTotal):not(.grandTotal) {
      cursor: pointer;
      user-select: none;

      @media (hover: hover) {
        &:hover {
          background-color: $gray8;
        }
      }
    }

    >div {
      padding: 0 5px;

      &:first-child::after {
        content: ": ";
      }

      &:not(:first-child) {
        font-weight: 500;
      }
    }
  }
}

.upgradeButton {
  width: 100vw;
  position: relative;

  button {
    width: 100%;
    background-color: #ff8223;
    color: black;

    &:hover {
      background-color: darken(#ff8223, 10%);
    }
  }
}