@import 'Colors.scss';

.root {
  position: relative;

  &:hover .chevronRightIcon {
   visibility: visible;
  }
}

.chevronRightIcon {
  visibility: hidden;
  position: absolute;
  right: -2px;
  top: 50%;
  transform: translateY(-63%) scale(0.6, 0.8);
  pointer-events: none;
  color: $gray3;

  &.isSelected {
    color: $action;
  }
}
