@import "Colors.scss";

.root,
.iframeContainer,
.homepage {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.iframeContainer iframe {
  flex: 1;
}

.homepage {
  overflow-y: auto;
}

.homepageWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.homepageHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;

  img {
    height: 62px;
  }
}

.homepageHeaderText {
  display: flex;
  flex-direction: column;
}

// duplicate
.appName {
  position: relative;
  margin: 2px 0px 0 15px;
  color: #07113e;
  font-family: "renogareregular";
  font-size: 34px;
  display: flex;
  align-items: flex-end;
}

.browserText {
  font-size: 12px;
  color: $primary;
  font-weight: 300;
  margin-left: 16px;
}

/* copy pasted style for url bar */
.UrlBar {
  z-index: 1;
  background: white;
  padding: 5px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid $gray4;
  position: relative;
}

.UrlBar-controls,
.UrlBar-searchWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.UrlBar-controls {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 6px;
}

.UrlBar-searchWrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}

.UrlBar-controlBtn {
  outline: none;
  cursor: pointer;
  background: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 5px 5px;
  margin: 0 -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #626262;
  font-size: 21px;
}

.UrlBar-controlBtn[disabled] {
  cursor: default !important;
  -webkit-transition: none;
  transition: none;
  color: #d3d3d3;
}

.BrowserUrlField-wrapper {
  display: flex;
  background: $secondary;
  flex: 1;
  margin-right: 10px;
  height: 24px;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.BrowserUrlField-wrapper #BrowserUrlField .public-DraftEditor-content {
  padding: 0 5px;
  outline: none;
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.BrowserUrlField-wrapper #BrowserUrlField {
  position: absolute;
  margin: 4px 0;
  background: #fff;
  border: 1px solid #ccc;
  color: #888;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Droid Sans,
    Helvetica Neue, Arial, Helvetica, sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  height: 27px;
  overflow: hidden;
  width: 100%;
  min-width: 50px;
}

.BrowserUrlField-wrapper #BrowserUrlField .public-DraftStyleDefault-block {
  margin-left: 20px;
  padding: 5.5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.BrowserUrlField-wrapper .secure-icon {
  z-index: 1;
  color: #828282;
  font-size: 13px;
  margin-top: -2px;
  margin-right: 5px;
}

.middleSearchBox {
  --ntp-realbox-height: 44px;
  border-radius: calc(0.5 * var(--ntp-realbox-height));
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  font-size: 16px;
  height: var(--ntp-realbox-height);
  margin-bottom: 20px;

  .inputWrapper {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    svg {
      margin-left: 10px;
      color: $gray3;
    }
  }

  input {
    background-color: white;
    border: none;
    border-radius: calc(0.5 * var(--ntp-realbox-height));
    color: var(--search-box-text);
    font-family: inherit;
    font-size: inherit;
    height: 100%;
    outline: none;
    padding-inline-end: 44px;
    padding-inline-start: 52px;
    position: relative;
    width: 100%;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  input::placeholder {
    color: $gray3;
  }
}

.ntp-realbox-icon {
  height: 100%;
  left: 12px;
  position: absolute;
  top: 0;
}

.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  width: 100%;

  .tile {
    position: relative;
    height: 120px;
    margin-bottom: 3px;
    margin-right: 3px;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    background-color: $gray11;

    @media (hover: hover) {
      &:hover {
        background-color: $secondary;
      }
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 65px;
    position: relative;

    .icon {
      width: 80%;
      height: 80%;
      mix-blend-mode: multiply;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .badge {
    position: absolute;
    top: 0;
    left: 0;
    stroke: #eeeef1;
    stroke-width: 1px;
    /* --badge-width: 35px; */
    width: var(--badge-width);
    height: var(--badge-width);
    text-transform: uppercase;
    transform: scale(0.9, 1);
    background: #eeeef1;
    color: #000000;
    padding: 3px 5px 3px 5px;
    transform-origin: left;
    font-size: 10px;
    letter-spacing: 0px;
    display: flex;
    align-items: center;

    >svg {
      width: var(--badge-width);
      height: var(--badge-width);
    }
  }

  .title {
    margin-top: 8px;
    color: $text;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
  }
}

.linkButton {
  color: $action;
  font-weight: 500;
  cursor: pointer;
}

.instructions {
  margin-top: 10px;
  margin-bottom: 20px;
  color: $primary;
  text-align: center;

  >.smallText {
    margin-top: 20px;
    font-size: 12px;
    line-height: 15px;
  }
}

.clearPriceUpdate {
  margin-top: 20px;
  flex: 1;
  font-size: 12px;
  color: $primary;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.mouseOverOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
}

:global(.active.reflex-splitter)+div .mouseOverOverlay {
  display: block;
}