@import "Colors.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  min-height: 0;
  flex-direction: column;

  > div.sizeMeContainer {
    max-width: 100%;
    flex: 1;
    
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    svg:global(.ShapePreview) {
      width: 100%;
      height: 100%;
    }
  }

  @media(hover: hover) {
    &:hover .deleteIcon {
      visibility: visible;
    }
  }
}

.clickable {
  cursor: pointer;
}

.flexColumn {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  max-height: 140px;
}

// duplicate
.enterQuantityLink {
  font-size: 13px;
  color: $action;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 14px;
}

// duplicate
.button {
  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    margin-right: 10px !important;
  }
}

.deleteIcon {
  position: absolute;
  right: 6px;
  bottom: 12px;
  transform: scale(1.2);
  cursor: pointer;
  visibility: hidden;
}

.rectangularOptions {
  max-height: inherit;
  color: #808085;
  font-size: 13px;
  letter-spacing: 0.5px;
}