@import "Constants.scss";

.root {
  margin-bottom: 5px;
}

.datePicker {
  width: 231px; //hack

  @media(max-width: $vertical-phone-max-width) {
    margin-top: 5px;
    width: auto;
  }
}