@import "Colors.scss";

.root {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tableHeaderTitle {
  height: auto !important;
}

.tableHeaderTitleTh {
  border-bottom: none !important;
  padding-top: 3px !important;
  padding-left: 12px !important;
}

.tableHeaderQuantity,
.tableHeaderUnit {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  //font-style: italic;
}

.tableHeaderUnit {
  padding-left: 7px !important;
}

.tableValueQuantity input {
  padding-right: 16px !important;
}

.tableValueUnit select {
}

.tableValueQuantity:not(:hover) > div::before,
.tableValueUnit:not(:hover) > div::before {
  // border-bottom: 1px solid rgb(224, 224, 224) !important;
}

.unitComboBox {
  width: 100%;
}

.addQuantityButton {
  color: $primary;
  font-weight: normal;
  margin-bottom: 10px;
}