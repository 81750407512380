@import 'Colors.scss';

// style for this component is spread out between here and parent

.root {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 12px;
}

.avatar {
  //duplicate
  //background-color: white !important;
  border: 1px solid #f0f0f1 !important;
}

.description {
  line-height: 1 !important;
  padding: 0 4px !important;
  //font-weight: 400 !important;
  letter-spacing: 0.5px !important;
}

// duplicate
.acceptButtonIcon {
  color: $green !important;
}

.cancelButtonIcon {
  color: $red !important;
}

.textField {
  width: 300px;

  input {
    padding: 8px 8px 6px !important;
  }
}