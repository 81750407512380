@import "Colors.scss";

.root {
  &.isEditable {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        background: $secondary;
      }
    }
  }
  user-select: none;
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;

  .label:not(:empty) {
    margin-right: 10px;
    &::after {
      content: ": ";
    }
  }
  .value {
    font-weight: 500;
  }
}

.rootEdit {
  display: flex;
  align-items: center;
}

.combobox {
  min-width: 200px;
}

// need a way for parent component to set
.textField {
  // width: 150px;
}

.unitsCombobox {
  width: 100px;
}

.detailsButton {
  color: $primary;
}

.acceptButtonIcon {
  color: $green;
}

.cancelButtonIcon {
  color: $red;
}

.editTools {
  display: flex;
  justify-content: flex-end;
}
