@import 'Colors.scss';


.root {
  height: 60vh;
  //width: 40vw;
  display: flex;
  flex-direction: column;
}

.advancedButtonContainer {
  flex: 1;
}

// duplicate
.enterQuantityLink {
  font-size: 13px;
  //color: $action;
  opacity: 0.7;
  text-decoration: underline;
  cursor: pointer;
  padding: 10px
}


.title {
  display: flex;
  align-items: center;
  // move to theme
  margin-left: 17px;
  margin-top: 11px;
}

.searchToolbar {
  background-color: transparent;
}

.subtitle {
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: $text;
}

// duplicate
.createItem{
  display: block;
  color: $action;
  text-decoration: underline;
  cursor: pointer;
}

// make checkbox visible
/*
.list {
  :global(.MuiCheckbox-root) {
    opacity: 1;
  }
  :global(.MuiListSubheader-root) span:not(:first-child) {
    margin-left: 35px !important;
  }
}*/