@import 'Colors.scss';

.root {
  border-top: 2px solid $border;
  padding: 10px;
  position: relative;
}

.hideButton {
  position: absolute;
  right: -6px;
  top: -4px;
  scale: 0.6;
}

.notFoundMessage {
  display: flex;
  flex-direction: column;

  > div:first-child {
    display: flex;
    align-items: center;
    > svg {
      margin-right: 10px;
    }

    margin-bottom: 10px;
  }

  > div:not(:first-child) {
    font-size: 10px;
  }
}

.providedQuantity, .buttonWithDetails {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.buttonWithDetails {
  justify-content: space-between;
}

.providedQuantity {
  margin-bottom: 10px;
}

.providingItem {
  position: relative;
  margin: 3px -10px 10px -10px;
}

.detectedItem, .itemWithProvidedQuantity, .itemWithButtons {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rentalPeriod {
  display: flex;
  align-items: center;
  margin: 5px 0px 16px;

  h6 {
    display: block;
    width: auto;
    margin-right: 20px;
  }
}

.itemWithProvidedQuantity {
  margin-bottom: 10px;
}

.subtitle {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 3px;

  &:hover .infoButton {
    visibility: visible;
  }
}

.infoButton {
  visibility: hidden;
}

.textField, .unitComboBox {
  flex: 1;
}

.textField input {
  padding: 7px 16px;
}

.actionButton {
  color: $body;
  background-color: $action;
}