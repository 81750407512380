@import 'Colors.scss';

.root {

}

.isAskingToDelete {
    background-color: rgba($red, 0.11);
  
    &:hover {
      background-color: rgba($red, 0.2);
    }
  }
  