@import "Colors.scss";
@import "Constants.scss";

.root {
  color: rgba(0, 0, 0, 0.54);

  :global(.MuiPaper-root) {
    color: rgba(0, 0, 0, 0.54);
  }
}

.subtitle {
  margin-top: 25px;
  font-size: 14px;
  color: $text;
}

.currentPlan {
  @media (min-width: $vertical-phone-max-width+1) {
    height: 150px;
    width: 50%;
  }

  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;

  &.hasSelectedPlan {
    height: 0;
    margin-bottom: 0;
    width: 0%;
  }
}

.content {
  align-items: flex-start;
  padding-top: 20px;
}

.icon {
  width: 150px;
  margin-left: 30px;


}

.name {
  color: black;
  font-size: 26px;
  white-space: nowrap;
}

.cards {
  display: flex;
  margin: 0 -10px;
  align-items: stretch;

  @media (max-width: $vertical-phone-max-width) {
    flex-wrap: wrap;
  }

  @media (min-width: $vertical-phone-max-width+1) {
    width: 1000px;
    transition: width 0.5s;

    &.hasSelection {
      width: 500px;
    }
  }
}

.cardContainer {
  @media (min-width: $vertical-phone-max-width+1) {
    width: 50%;
  }

  padding: 5px 10px;
  transition: width 5 0.5s;
  min-width: 0;
  display: flex;
  overflow: hidden;

  &.isSelected {
    width: 100%;
  }

  &:first-of-type>div {
    border: 2px solid $action;
    box-shadow: none;
    border-radius: 11px;
  }

  &.isHidden {
    width: 0%;
    padding: 0;
    //width: 0%;

    >div {
      //display: none;
      border: none !important;
    }
  }
}

.card {
  display: flex;
  align-items: stretch;
  flex: 1;
  padding: 30px 0;

  @media (min-width: $vertical-phone-max-width+1) {
    min-width: 400px;
  }

  >div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  &:not(:first-of-type) {
    box-shadow: 0 2px 4px 0 hsla(198, 45%, 10%, 0.12);
  }
}

.cardContent {
  display: flex;
  align-items: stretch;
  flex: 1;
  padding: 0 16px;
  padding-bottom: 0 !important;

  form {
    flex: 1;
  }
}

.priceAndOffer {
  display: flex;
  align-items: baseline;
}

.price {
  font-size: 18px;
  font-weight: 500;

  span:first-of-type {
    font-size: 32px;
    font-weight: 500;
  }

  margin-right: 10px;
}

.offer {
  display: flex;
  align-items: center;
  color: $action;

  svg {
    margin-right: 8px;
  }
}

.crossedPrice {
  text-decoration: line-through;
  margin-right: 10px;

  span:first-of-type {
    font-size: 20px;
  }
}

.actionButton {
  margin: 20px 0;
  font-size: 18px;
  padding: 10px 15px;
}

.selectedButton {
  color: $action;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  margin: 20px;
}

.root {
  form {
    transition: max-height 0.5s;
    max-height: 500px;
    overflow: hidden;

    button {
      width: 100%;
      margin-top: 6px !important;
      padding: 13px !important;
    }
  }

  form.isHidden {
    max-height: 0;
  }
}

.featuresAndForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cardRelative {
  position: relative;
}

.creditCardContainer {
  position: absolute;
  left: 0;
  top: 5px;
  width: 100%;
  height: 52px;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &:not(.isFocused):hover {
    background-color: #eeeef1;
  }
}

.cardInput {
  position: absolute;
  left: 0;
  top: 8px;
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;

  >div {
    flex: 1;
  }
}

.fakeTextField {
  pointer-events: none;

  &.isFocused {
    label {
      color: $action;
    }

    >div {
      background-color: #133e6a1f !important;

      &::after {
        transform: scaleX(1);
      }
    }
  }
}

.detailedPrice {
  display: flex;
  flex-direction: column;

  >div {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      font-weight: 500;
    }
  }

  .total {
    font-size: 17px;
    color: black;
    font-weight: 500;
    margin: 10px 0;

    >div:first-of-type::after {
      content: ":";
    }
  }
}