@import "Colors.scss";

.root {
    th {
        white-space: no-wrap;
    }
}

.itemCategoriesHeader {
    display: flex;
    align-items: center;

    svg {
        cursor: pointer;
        margin-left: 10px;
        color: $gray3;
    }
}

.textFieldNoLabel {
    padding: 0 9px;
    background-color: transparent;
    width: calc(100% - 12px);

    &::before {
        right: -12px;
    }

    >div {
        padding: 8px 32px 6px 8px;
    }

    label {
        display: none !important;
    }
}

.categoriesCombobox {
    min-width: 350px;

    >div {
        margin: 0;
    }

    >div>div {
        background-color: transparent;
    }

    :global(.combobox-placeholder) {
        top: 7px;
    }
}

.categoriesComboboxInput {
    padding: 2px 7px;
    align-items: flex-end;
}

.categoriesComboboxCell:empty {
    position: relative;

    &::before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid rgba(2, 28, 51, 0.46);
        pointer-events: none;
    }
}

.subtypeSelect,
.categoriesOptionsSelect {
    left: 0;
}