
.root {
  width: 80px;
}

.textFieldNoLabel {
  select {
    padding: 8px 32px 6px 8px;
  }
  label {
    display: none !important;
  }
}