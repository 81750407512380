.root {
  user-select: text;

  td,
  th {
    vertical-align: middle;
    height: 55px;
    padding: 0 10px;
  }

  table {
    border: 1px solid #cbcbcb;
    border-spacing: 0;
    empty-cells: show;
  }

  table td,
  table th {
    border-bottom-width: 0;
    border-left: 1px solid #cbcbcb;
    border-right-width: 0;
    border-top-width: 0;
    font-size: inherit;
    margin: 0;
    overflow: visible;
  }

  table thead {
    background-color: #e0e0e0;
    color: #000;
    text-align: left;
    vertical-align: bottom;
  }

  table td {
    border-bottom: 1px solid #cbcbcb;
  }

  table tbody > tr:last-child > td,
  table tbody > tr:last-child > td {
    border-bottom-width: 0;
  }

  table td,
  table th {
    border-width: 0 0 1px;
    border-bottom: 1px solid #cbcbcb;
  }
}
