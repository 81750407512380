@import "Colors.scss";

.root {
  flex: 1;

  // remove regular panel padding, to control it better
  margin: -2px -2px -2px -10px;
  

  .main {
    height: 44px;
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
    padding: 2px 2px 2px 10px;
  }

  .message {
    padding: 2px 2px 2px 10px;
    height: 34px;
    background-color: white;
    display: flex;
    align-items: center;
    color: $primary;
    border-bottom: 2px solid $gray11;

    svg {
      margin-right: 5px;
    }
  }
}

/* duplicate Worksheetsstreeheadermodule */
.title {
  flex: 1;
  margin-left: 1px;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 5px;
  }
}

.button {
  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    // margin-right: 5px !important;
  }
}

.buttons {
  display: flex;
  align-items: center;
}


.hidden {
  visibility: hidden;
}

.hoverButtonsRight {
  position: absolute;
  right: 0;
  top: 0;
  visibility: hidden;
  background-color: $secondary;
  z-index: 1;
  min-width: 43px;
  display: flex;
  justify-content: flex-end;
}

.root:hover .hoverButtonsRight {
  visibility: visible;
}

.deleteButton {
  color: $red !important;
}

.checkbox {
  margin: 0 0 0 -7px !important;
}

//duplicate
.details {
  margin-right: 8px;
  margin-left: 10px;
  color: $gray3;
  font-size: 13px;

  font-weight: 500;

  min-width: 60px;
  text-align: right;
}

.detailsNumberOfTasks {
  opacity: 0.9;

  &::after {
    content: "-";
    margin: 0 8px;
  }
}

.textFieldNoLabel {
  select {
    padding: 8px 32px 6px 8px;
  }

  label {
    display: none !important;
  }
}

.categoryFilter {
  margin-bottom: 3px !important;
  margin-right: 4px !important;
}

.funnelIcon {
  color: $action !important;
}

.funnelRemove {
  color: #f48500 !important;
}

.filterCombobox {
  min-width: 250px;
}