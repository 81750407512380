@import 'Colors.scss';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

// todo should completely hide the panel, but panelgroup doesnt support it yet
.hidden {
  visibility: hidden;
}

.borderContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.header {
  user-select: none;
  flex-shrink: 0;
  background-color: $secondary;
  color: $gray3;
  padding: 2px 2px 2px 10px;
  letter-spacing: 0.5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 44px;
}

.content {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}