.root {
    max-width: 860px;
}

.readonlyMessage {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 15px 5px 25px;
    width: 100%;
    line-height: 1.2;
  
    svg {
      margin-right: 10px;
    }
  }