@import 'Colors.scss';

.root {
  background-color: $gray11;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-weight: 400;
  //min-height: 30px;

  // hack to ensure plan doesn't jump when tools appear
  height: 60px;
  margin-bottom:-60px;
  z-index: 1;

  > div {
    margin-right: 20px;
  }
}

.disabled {
  > * {
    opacity: 0.5;
    pointer-events: none;
  }
}

.drawingTypeIcon {
  margin-right: 10px;
}

.textField {
  width: 200px;
}

.editableItem {

}

.combobox {
  min-width: 200px;
}