@import "Colors.scss";

.SurfaceComponent {
  color: #e6e3e3;

  .fill {
    fill: currentColor;
    fill-opacity: 0.5;
  }

  &.isHidden {
    visibility: hidden;
  }

  .measurementPath {
    fill: none;
  }

  .measurementPath {
    stroke-width: 3px;
    stroke: rgba(7, 18, 61, 0.45);
  }

  // temporary hack
  .repeatedLine0 {
    stroke-width: 5px;
  }

  .repeatedLine1 {
    stroke-dasharray: 6;
  }

  .offsetPath {
    stroke-dasharray: 16;
    fill: $action;
    opacity: 0.15;

    &.internalOffset {
      opacity: .8
    }
  }

  &.isHighlightedFromMeasurement {
    filter: brightness(1.2);
  }
}


.nodeIsSelected .SurfaceComponent {
  color: $action;

  .fill {
    fill-opacity: 0.8;
    transition: filter 0.5s;
  }
}

@media (hover: hover) {
  #svgTag.shouldHover .SurfaceComponent:hover {
    .fill {
      filter: brightness(0.8);
    }

    &:hover {

      text,
      .DimensionSurface {
        display: block;
      }
    }
  }
}