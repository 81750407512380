.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.footer {
  font-size: calc(var(--report-font-size-scale) * 13.4px);

  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  padding: 74px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerCopyright {
  font-size: calc(var(--report-font-size-scale) * 13.4px);
  opacity: 0.5;

  &.shouldShowReportInitials {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.initials {
  font-size: calc(var(--report-font-size-scale) * 13.4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reportNumber,
.pageNumber {
  white-space: nowrap;
}