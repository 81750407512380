
.root {

}

.bigImage {
  position: relative;

  flex-wrap: wrap;
  width: 80px;
  height: 80px;
}