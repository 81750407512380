@import "Colors.scss";

.foreignContainer {
  display: flex;
  justify-content: center;
}

.foreignContainerLengthInput {
  width: 150;
  display: flex;
  align-items: center;

  &.isTouchDevice {
    background-color: rgba(255,255,255,0.6);
    pointer-events: auto;
  }
}

.textField {
  z-index: 0; // buggy if we don't put this
  background: #ffffffe8;

  input {
    font-size: 20px;
  }
}

.textFieldNoLabel {
  input {
    padding: 8px 8px 6px;
  }

  label {
    display: none !important;
  }
}

.cancelButtonIcon {
  color: $red;
}

.acceptButtonIcon {
  color: $green;
}