@import "Colors.scss";

.root {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0px 6px 0 0;
  z-index: 0;
  min-width: 0;
  height: 40px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    height: 40px;
    left: -1px;
    right: 0;
    z-index: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &.isSelected::before {
    //left: -100px;
  }

  &.isCopyDrawingTarget {
    outline: 2px solid $highlight;
  }

  >* {
    z-index: 1;
  }

  :global(.ShapePreview) {
    pointer-events: none;
  }
}

.bubbleMenuItem {
  >span {
    margin-left: -5px;
  }

  >svg {
    vertical-align: middle;
    margin-bottom: 2px;
    margin-left: -2px;
    margin-right: -4px;
  }
}

.multiplierCheckbox {
  margin-left: -5px;
  margin-right: -4px;
}

.default {
  display: flex;
  align-items: center;
  //pointer-events: none;
  flex: 1;
  color: $text;
  min-width: 0;

  .multiplierIcon {
    margin-right: 2px;
  }
}

.avatar {
  //order: 01;
  display: flex;
  align-items: center;

  &:empty {
    width: 10px;
  }
}

.avatarImage {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.hasTasks .default {
  //font-weight: 500;
  //color: black;
  font-style: italic;
}

.isSelected {
  //color: $secondary;

  &::before {
    background: $highlight !important;
  }

  .buttons {
    background-color: rgba($highlight, 0.9);
    visibility: visible;
  }

  &.hasNoMeasurements .bubbleIcon {
    stroke: $highlight;
  }
}

.shouldExcludeFromReports {
  text-decoration: line-through;
}

.hasNoMeasurements .bubbleIcon {
  stroke: white;
  opacity: 0.5;
}

.status {
  padding: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;

  svg {
    width: 22px;
  }
}

.note {
  svg {
    width: 22px;
  }

  margin-left:5px;
  cursor: pointer;
  color: $orange;
  display: flex;
  align-items: center;
}

.isHidden {
  .default {
    opacity: 0.4;
  }

  .buttons {
    opacity: 0.7;
  }
}

.root.isHidden {
  .buttons {
    visibility: visible;
    background-color: rgba($gray11, 0.9);

    >*:not(.visibilityButton) {
      visibility: hidden;
    }
  }
}

.buttons {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
  visibility: hidden;
  position: absolute;
  right: 0;
}

.button>* {
  pointer-events: none;
}

@media (hover: hover) {
  :global(.draggable-tree):not(:global(.isDragging)) {
    .root:hover {
      &:not(.isSelected)::before {
        background-color: $gray11;
      }

      &.isSelected {
        &::before {
          opacity: 0.8;
        }

        .buttons {
          background-color: lighten($highlight, 2%);
        }
      }

      .buttons {
        visibility: visible;
        background-color: $gray11;
      }
    }
  }
}

.multiplySecondStep>div {
  display: flex;
  align-items: center;
}

.acceptButtonIcon {
  color: $green;
}

.cancelButtonIcon {
  color: $red;
}

.textFieldNoLabel input {
  padding: 8px 8px 6px;
}

.preview {
  margin-right: 15px;

  &:empty {
    width: auto;
    //    width: 0px !important;
    //    margin-right: 0 !important;
    background-color: rgba(215, 215, 220, 0.2);
    //border: 1px solid white;
    border-radius: 100%;
  }
}

.bubbleIcon {
  transform: scale(0.8) translate(-1px, -2px);
  margin-left: -11px;
  color: #535353;
  pointer-events: none;
}

$alphavalue: 0.01;
$indentIncrement: 2.5;
$indentColor: $primary;

.indentLevel0::before {
  background-color: rgba($indentColor, $alphavalue * 0 * $indentIncrement);
}

.indentLevel1::before {
  background-color: rgba($indentColor, $alphavalue * 0 * $indentIncrement);
}

.indentLevel0 .nodeText {
  //font-size: 16px;
  letter-spacing: 0.15px;
  font-weight: 400;
}

.indentLevel1 .nodeText {
  font-size: 14px;
  letter-spacing: 0.1px;
}

.indentLevel2 .nodeText {
  font-size: 13px;
}

.indentLevel3 .nodeText {
  font-size: 13px;
}

.nodeText {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis; // doesnt work
  padding-right: 2px;
  line-height: 18px;
  text-wrap: nowrap;
}