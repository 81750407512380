@import 'Colors.scss';

.root {
  stroke: blue;
  stroke-width: 5px;
}

.foreignContainer {
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  display: flex;
  flex-direction: column;

  >div {
    background-color: white;
  }

  button {
    margin: 5px 0;
  }

  svg {
    stroke: none;
  }
}

.firstRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  >div:first-of-type {
    flex: 1;
  }
}

.unitSystem.textField {
  margin-left: 10px;
  min-width: 140px;
}

.actionButton {
  background-color: $action;
  color: white;
}