@import "Colors.scss";

.root {
  user-select: text !important;

  .providingItem {
    user-select: text;
    cursor: text;

    >div:first-child {
      user-select: none;
    }

    :global(.MuiTypography-root) {
      user-select: text;
    }

    &:not(.shouldShowIcons)>div:first-child {
      display: none
    }
  }

  // this won't work, needs :global, but have to check why was there in the first place
  tr.continued>th:first-child::after {
    font-weight: normal;
    text-transform: none;
  }

  &:not(.hasTasks) {
    margin-bottom: 20px;
  }

  table {
    table-layout: fixed; // ?
    min-width: 100%;
  }

  th {
    vertical-align: bottom;
    white-space: nowrap;
  }

  tr.shouldShowHeaderMeasurements th {
    vertical-align: top;
  }

  td {
    vertical-align: middle;
  }

  tr {}

  th,
  td {
    text-align: right;
    padding: 10px;
  }

  tr:nth-child(even) td {
    background: white;
  }

  .spacingHeaderRow th {
    line-height: 5px;
    padding: 0;
  }

  .spacingTasksRow td {
    line-height: 40px;
    padding: 0;
    background-color: white !important;
  }

  .inlineNotes {
    background-color: white;
  }

  .spacingTasksRow:has(+.inlineNotes) td {
    line-height: 30px;
  }

  .inlineNotes {
    td>div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      >div:first-of-type {
        //text-transform: uppercase;
        font-size: calc(var(--report-font-size-scale) * 12px);
        color: black;
      }
    }

    .richText>div {
      font-size: calc(var(--report-font-size-scale) * 13px) !important;
      padding-top: 5px !important;

      ol {
        margin-top: 0 !important;
        //padding-left: 0 !important;
      }

      li {
        //
      }
    }

  }

  .flexContainer {
    display: flex;
    align-items: center;
  }

  .lineNumber {
    user-select: none;
    text-align: left;
    padding: 0 2px;
    color: rgba(0, 0, 0, 0.5);
    min-width: 22px;
  }

  .groupRow {
    font-size: calc(var(--report-font-size-scale) * 13px);
    background-color: var(--logo-color-pale);
    color: black;

    >th {
      font-family: Montserrat !important;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    .groupName {
      white-space: normal;
      width: 100%;
      position: relative;
      text-align: left;
    }
  }

  .groupRow.isEmphasized {
    background-color: var(--logo-color);
    color: var(--logo-color-text);
    font-weight: 400;

    text-align: left;

    >th {
      padding: 12px 10px;

      >span:last-child {
        //font-weight: 600;
        font-family: MontserratSemiBold;
      }
    }

    .groupName {
      font-weight: 400;
      font-family: MontserratSemiBold !important;
    }
  }

  .groupRow.isSemiEmphasized {
    font-size: calc(var(--report-font-size-scale) * 12px);
    // font-weight: 500
    font-family: MontserratMedium;
    letter-spacing: 0;
    margin-top: 10px;

    border-top: 2px solid var(--logo-color);
    border-bottom: 1px solid var(--logo-color);
    background-color: transparent !important;

    .groupName {
      width: 100%;
    }
  }

  .tasksHeader th {
    padding-top: 16px;
    font-size: calc(var(--report-font-size-scale) * 12px);
    color: black;
    padding-bottom: 10px;
    font-family: Montserrat;
  }

  .categoryImage {
    width: 20px;
    height: 20px;
    margin-right: 5px;

    >div {
      width: 20px;
      height: 20px;
    }
  }

  .measurement {
    width: 250px;
  }

  .unitPrice {
    text-align: left;
    padding-left: 35px !important;

    >.updatedInLastPriceUpdate {
      font-family: MontserratBold;
      font-weight: bold;
    }
  }

  &.shouldShowTaskUnitPrice .unitPrice {
    text-align: right;
    padding-left: 25px !important;
  }

  th.providingItemCell {
    text-align: left;
  }

  td.providingItemCell {
    text-align: left;
    vertical-align: middle;

    >div {
      display: inline-flex;
    }
  }

  .measurement,
  .unitPrice,
  .taskPrice {
    white-space: nowrap;
  }

  .separator {
    font-size: calc(var(--report-font-size-scale) * 13px);
    font-style: italic;
  }

  .task {

    &,
    .providingItem span,
    .providingItem p {
      white-space: normal;
      letter-spacing: 0;
      font-size: calc(var(--report-font-size-scale) * 13px);
      font-weight: normal;
      color: black;
      font-family: Montserrat;
    }

    .providingItem p {
      opacity: 0.8;
      font-size: calc(var(--report-font-size-scale) * 12px);
    }

    .providingItem span {
      display: inline-block;
    }

    td {
      padding: 2px 5px;
    }
  }

  .emptyRow {
    height: 40px;
  }

  .shapePreview {
    // transform: scale(1.5);
  }

  .roomMeasurements {
    th {
      background-color: var(--logo-color-pale);
    }

    div {
      min-width: 0;

      td:first-child {
        padding-left: 0;
      }

      td {
        background: transparent !important;
        padding: 4px 13px;
        text-align: left;
        font-style: italic;
        font-size: calc(var(--report-font-size-scale) * 13px);
      }

      td:nth-child(even) {
        padding-left: 0;
      }
    }
  }

  // TERRIBle hack... convert measurements into spacer row, because modified @progress html/core.js removes spacing row by mistake
  .mainReportContentList tr:global(.continued)+.roomMeasurements th {
    line-height: 5px;
    height: 5px;
    padding: 0;
    opacity: 0;

    &::before {
      display: none;
    }

    >div {
      display: none !important;
    }
  }

  @mixin indentLevel($level) {

    >th:first-child,
    >td:first-child {
      position: relative;
      padding-left: 10px + 20px * $level !important;

      &::before {
        content: "";
        display: block;
        width: 20px * $level;
        height: calc(100% + 4px);
        position: absolute;
        left: 0;
        top: -2px;
        background: white;
      }
    }

    //border-left: ($level * 20px + 20px) solid white;
  }

  /*


*/

  .indentLevel0 {
    @include indentLevel(0);
  }

  .indentLevel1 {
    @include indentLevel(1);
  }

  .indentLevel2 {
    @include indentLevel(2);
  }

  .indentLevel3 {
    @include indentLevel(3);
  }

  .indentLevel4 {
    @include indentLevel(4);
  }

  .indentLevel5 {
    @include indentLevel(5);
  }

  .indentLevel6 {
    @include indentLevel(6);
  }

  .indentLevel7 {
    @include indentLevel(7);
  }

  .indentLevel7 {
    @include indentLevel(8);
  }
}