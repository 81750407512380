.root {
  position: relative;
  
  > div {
    overflow: hidden;
    position: absolute;

    img {
      position: absolute;
    }
  }

  +button[data-rmiz-btn-open] {
    cursor: pointer !important;
  }
}
