.root {
  min-width: 400px;

  h2 {
    display: flex;
    align-items: center;
    margin-right: -7px;

    >div:first-of-type {
      flex: 1;
    }
  }
}

.checkboxAndLabel {
  display: flex;
  align-items: center;
}

.textField {
  width: 100%;
}

.section {
  margin-top: 14px;
}

.colorRow {
  display: flex;
  align-items: center;
  margin-top: 7px;
  margin-left: 6px;

  >button {
    margin-left: 5px;
  }
}

.colorButton {}