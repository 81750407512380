@import 'Colors.scss';

.root {
    display: flex;

    .goToEditModeButton {
        //flex: 1;
        //background-color: $gray10;
        margin-top: 10px;
        font-size: 13px;
        color: $gray3;
        text-decoration: underline;
    }

    .editMode {
        padding: 10px;
        background-color: $gray11;

        .title {
            font-size: 14px;
            color: $gray5;
            margin-bottom: 10px;
        }

        .instructions {
            font-size: 13px;
            max-width: 525px;
            margin-bottom: 10px;
            line-height: 1.1;
        }

        .components {
            display: flex;
            align-items: center;

            >div {
                flex: 1;
            }
        }

        .replaceAllButton {
            height: 53px;
            margin-left: 10px;
        }

        .replacementsMade {
            font-size: 15px;
            margin-top: 16px;
            margin-bottom: 5px;
            margin-left: 0px;
            text-align: right;
        }
    }
}