@import "Colors.scss";

.root {
  display: flex;
  flex: 1;
  align-items: center;
}

.buttons {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  >button {
    //color: $action;
  }
}

.quickMode {
  display: flex;
  align-items: center;
  margin-right: 20px;

  svg {
    //color: $primary;
  }
}

// could apply to any kind of toggle in popup menu
.menuItem {
  min-width: 300px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.menuText {
  white-space: normal;
}

.slider {
  margin-top: 4px;
  margin-left: 2px;

  >span {
    width: 300px;
    margin: 2px 20px;
  }
}

.textFieldControl {
  margin-right: 10px;
  margin-left: 10px;
}

// duplicate
.textFieldNoLabel {
  padding: 0;
  background-color: transparent;
  //width: calc(100% - 12px);

  >div {
    padding: 8px 32px 6px 8px;
  }

  label {
    display: none !important;
  }
}

.undoButtons {
  margin-right: 30px;
  display: flex;
  align-items: center;
}