.firebaseui-idp-button,
.firebaseui-tenant-button {
    max-width: 400px !important;
}

div.firebaseui-container {
    min-width: 400px !important;
}

#firebaseui_container>div {
    margin: -46px 0 40px !important;
}

#firebaseui_container>div.firebaseui-id-page-provider-sign-in {
    margin: 0px 0 20px 0 !important;
}