.DimensionSurface {
  pointer-events: none;

  .surfacePath {
    stroke-width: 1;
    stroke: rgba(86, 91, 125, 0.67);
    stroke-dasharray: 12px 12px;
    fill: transparent;
  }

  .smallText {
    font-size: 20px;
    opacity: 0.4;
  }
}
