.root {
  display: flex;
  flex-direction: column;

  >div:first-of-type {
    padding: 10px;
  }

  :global(.compact-picker) {
    >div:nth-of-type(2) {
      display: none !important;
    }
  }

  // chrome picker
  >div:nth-of-type(2) {
    width: 100% !important;
    box-shadow: none !important;

    // chrome picker bottom sliders
    >div:nth-of-type(2) {
      >div:first-of-type {
        align-items: center;

        >div:first-of-type {
          width: 52px !important;

          >div {
            width: 35px !important;
            height: 35px !important;
          }
        }
      }

      >div:nth-of-type(2) {
        display: none !important
      }
    }
  }
}

.bottomButtons {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.swatch {
  position: relative;
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-block;
  cursor: pointer;
}

.paintIcon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 3px;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}