@import "Colors.scss";

.root {
  position: relative;
  width: 100%;
  height: 100%;

  :global(.formulaType0) h6 {
    border-bottom: 1px solid $action;
  }
}

.activeList {
  :global(.MuiAvatar-root) {
  //  display: none; // hack while we fix avatars for categ/subcategs too
  }
}

.draggableItem {
  &.isTouchDevice {
    -webkit-user-drag: none;
  }
  
  display: flex;
}

.noSelection {
  padding: 30px 20px;
  font-size: 0.9rem;
  font-style: italic;
  opacity: 0.7;
  font-weight: 300;
}

.inactiveMeasurementsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.cardContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 0 !important;
  min-height: 0;
}

.root:hover .fabButton {
  opacity: 1;
}

// duplicate
.button {
  margin: auto;
  align-self: center;

  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    margin-right: 10px !important;
  }
}
