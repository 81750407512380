@import "Colors.scss";

.root {
  /* not sure if it's better to align logo with address to save header space */
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 auto;

  label {
    cursor: pointer;

    &:not(.hasImage) {
      width: 100%;
    }
  }

  &:hover .uploadIcon,
  &:hover .deleteIcon {
    visibility: visible;
  }

  .mainPart {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;

    >div:first-of-type {
      display: flex;
      flex: 1;
      height: 100%;
    }
  }

}

.logoImage {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;

  >svg {
    color: $secondary;
    width: 50px;
    height: 50px;
  }
}

.icon {
  filter: unquote("grayscale(100%)");
  opacity: 0.4;
  width: 75px !important;
  height: 75px !important;
}

.uploadIcon,
.deleteIcon {
  position: absolute;
  right: 6px;
  bottom: 12px;
  transform: scale(1.2);
  cursor: pointer;
  visibility: hidden;
}

.uploadButtons {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  cursor: pointer;

  &.hasImage {
    bottom: 0;
    top: auto;
    height: auto;
  }

  svg {
    color: $gray3;
  }
}

.placeHolderText {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100px;
  height: 58px;
  transform: translate(0, -50%);
  opacity: 0.8;
}


.deleteIcon {
  right: auto;
  left: 6px;
}

.uploadIcon {
  padding: 2px 4px;

  @media (hover: hover) {
    &:hover {
      background-color: $secondary;
      border-radius: 100%;
    }
  }
}

.imageUrl {
  display: flex;
  align-items: center;

  .textField {
    flex: 1;
  }
}

.progress {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 21px);
  // can't use this because already transformed to animate
  //transform: translate(-50%, -50%);
}

div[data-rmiz-wrap="visible"] {
  width: 100%;
  height: 100%;
}

div[data-rmiz-modal-content="true"] {
  div {
    opacity: 1;
  }
}