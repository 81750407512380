@import "Colors.scss";
@import "Constants.scss";

.root {
  overflow-y: scroll; // scroll doesn't flicker, but is not aligned with icons
  flex: 1;
  position: relative;
}

.virtualList {
  scrollbar-width: thin;
}

.list {
  width: 100%;
  overflow-y: auto;
  position: relative;

  >li {
    height: 65px;
  }
}

.actionButton {
  color: $action !important;
}

.measurement {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: none;
  width: 0;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  transition: width 0.5s;
  padding: 6px 11px;

  >div:first-of-type span {
    max-height: 60px;
  }

  @media(max-width:$vertical-phone-max-width) {
    padding: 3px 4px 3px 20px;

    >div:first-of-type {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      flex: none;
      height: auto;
    }
  }
}

.middleLineTd {
  width: 0;
  flex-shrink: 0;
  transition: width 0.5s;
  overflow: hidden;
}

.material {
  min-width: 0; // makes text ellipsis possible for some reason
  flex: 1;

  >div {
    padding: 6px 10px;
    
    @media(max-width:$vertical-phone-max-width) {
      padding: 6px 10px 6px 3px;
    }
  }
  
}

.isMeasurementColumnVisible {
  .measurement {
    width: 33%;
  }

  .middleLineTd {
    width: 1px;
  }
}

.material {
  position: relative;
}

.paper {
  margin-top: 20px;
}

.title {
  color: $secondary !important;
  padding: 5px 8px;
}

.childCards {
  display: flex;
  margin: 8px;

  >div {
    margin: 8px;
  }
}

.listGroupHeader {
  display: flex;
}

.heading {
  color: $gray3 !important;
}

.collapseIcon {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></g></svg>');
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
}

.location {
  background: $gray7;
}

.detailsPaper {
  box-shadow: none !important;
}

.task {
  padding: 0;
}

.expansionPanel {
  flex: 1;
  margin-bottom: 0;
  margin-top: 0 !important;

  // BAD
  &.cannotBeToggled {
    transition: none;

    >div:last-child {
      transition: none;
    }
  }
}

.details {
  position: relative;
  flex-direction: column !important;
  padding: 0 0 11px !important;

  &.isEmpty {
    ul {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: -54px;
      width: 100%;
      height: 54px;

      &:global(.isDraggingOver) {
        background-color: rgba($highlight, 0.2);
      }
    }

    &.isDraggingTasks ul {
      pointer-events: all;
    }
  }

  &.isEmpty {
    padding: 0 !important;
  }
}

@keyframes highlightNewTask {
  100% {
    background-color: white;
    outline-color: transparent;
  }
}

.listItem {
  height: 100%;
  width: 100%;
  display: flex;

  &.highlightNewTask {
    outline: 1px solid $gray9;
    outline-offset: -3px;
    background-color: $gray11;
    animation: highlightNewTask 600ms 200ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
  }
}

.listItem.separatorRow {
  margin-top: 8px;
  border-top: 1px solid #f0f0f1;
  flex: 1 1;
  padding-left: 0 !important;
  display: flex;
  align-items: center;
}

.emptyListEntry {
  display: flex;
  width: 100%;
  align-items: flex-start;

  > div {
    width: 100%;
    display: flex;
    font-weight: 300;
    font-size: 0.9rem;
    font-style: italic;
    align-items: center;
    padding: 0 20px;
    min-height: 80px;
    justify-content: center;
  }

  outline: none !important;
  &::after {
    display: none !important;
  }
}

.emptyListText {
  margin: 0 30px;
  text-align: center;

  @media(max-width: $vertical-phone-max-width) {
    margin: 10px 10px;
  }

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
  }
}

.arrow {
  width: 140px;

  @media(max-width: $vertical-phone-max-width) {
    width: 55px;
  }

  &.left {
    transform: translateY(-11px) rotate(44deg);
  }

  &.right {
    transform: translateY(-11px) scaleX(-1) rotate(44deg);
  }

  &.top {}
}

.childTasks {
  background-color: white;
  padding-top: 10px;
  padding-right: 3px;
  padding-left: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 13px;
  font-weight: 300;
  color: $gray3;

  button {
    margin-left: 5px;
  }
}

.buttons {
  background-color: $gray11;
}

.infoButton {
  display: inline !important;
  color: $gray3 !important;
}

.addButton {
  color: $secondary !important;
}

// duplicate
.tip {
  padding: 12px 18px;
  font-size: 0.9rem;
  font-style: italic;
  opacity: 0.7;
  font-weight: 300;
}

.hoverToolsLeft,
.hoverToolsRight {
  position: absolute;
  opacity: 0.01;

  @media(hover: hover) {
    opacity: 0;
  }

  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  height: 100%;
  z-index: 2;

  &.isDragging {
    pointer-events: none;
  }
}

.hoverToolsLeft {
  left: 0px;
  padding-left: 15px;

  span:not(.Mui-selected) {
    //color: rgba($gray3, 0.5);
  }
}

.checkbox svg {
  width: 20px;
  margin-left: 1px;
}

.hoverToolsRight {
  top: 1px;
  right: 5px;
  width: 39px;
  display: flex;
  justify-content: flex-end;
}

.listItem:hover,
.listItem:active {

  .hoverToolsRight,
  .hoverToolsLeft {
    opacity: 1;
  }
}

.listItem.hasItemsChecked .hoverToolsLeft {
  opacity: 1;
}

.root:hover .fabButton {
  opacity: 1;
}

.checkboxTd {
  overflow: hidden;
  width: 29px;
  padding: 14px 11px;

  >span {
    margin-left: -7px;
    padding: 0 !important;
    padding-left: 4px !important;
  }
}

// separator td instead of just border on td,
// because the height and spacing is more previsible
.middleLineTd {
  padding-left: 0 !important;
  padding-right: 0 !important;
  position: relative;

  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::before {
      content: "";
      display: block;
      flex: 1;
      margin-top: 8px;
      margin-bottom: 8px;
      width: 1px;
      background-color: $border;
    }
  }
}

.hoverToolsRightTd {
  width: 0;
  position: relative;
}

// hack
.expansionPanelSummary:hover>div:first-child>div:first-child {
  visibility: visible !important;
}

.expansionPanelSummary> :first-child> :last-child {
  padding-right: 0px !important;
}

.isDraggingTasksListWithMultiCategs {
  flex: 1;
  //align-items: flex-start;
  justify-content: center;
}

// bad duplicate from orderableitem, but complicated because Drag & drop with react-window

.taskRow {
  width: 100%;
  position: relative;
  min-width: 500px;
  @media(max-width: $vertical-phone-max-width) {
    min-width: 200px;
  }
  height: 65px;
  background-color: white;

  @media (hover: hover) {
    &:hover>.dragHandle {
      visibility: visible;
    }
  }

  .dragHandle {
    @media (hover: hover) {
      visibility: hidden;
    }

    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: 1px;
    width: 11px;
    color: $gray8;
    z-index: 3;

    @media(max-width:$vertical-phone-max-width) {
      left: 4px;
    }

    >svg {
      @media (hover: hover) {
        margin-left: -5px;
      }

      width: 20px;
    }
  }
}