@import 'Colors.scss';
@import 'Constants.scss';

.root {
  margin-left: 20px;
  min-width: 400px;

  @media(max-width: $vertical-phone-max-width) {
    margin-left: 0;
    min-width: 200px;
  }
}

.button {
  padding: 0 !important;
}

.subMenuButton {
  display: flex;
  align-items: center;
}

.menuItem {

}

.danger {
  border-top: 1px solid $border;
  color: $red;
}

.isGroupStart {
  border-top: 1px solid $border;
}

.isAskingToDelete {
  background-color: rgba($red, 0.1);

  &:hover {
    background-color: rgba($red, 0.2);
  }
}

.icon {
  margin-right: 5px;
  display: flex;
  align-items: center;

  > span {
    padding: 0;
    width: auto;
    height: auto;
  }
}

.isSubmenu >div:global(.MuiPaper-root) {
  transform: translate(-29px, 0) !important;
}