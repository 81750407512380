@import 'Colors.scss';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.6);
  border-radius: 2px;
  cursor: pointer;
}

.container {
  display: flex;
  align-items: center;
  background: rgba(255,255,255,0.9);
  border-radius: 30px;
  border: 1px solid $action;
  padding: 3px 8px;
  color: $action;
}