@import 'Colors.scss';

.root {
  min-width: 300px;
}

.header {
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 400;
  color: $gray5;
}

.formRow {
  display: flex;
  align-items: center;

  >* {
    flex: 1;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.formColumn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

//duplicate
.formIcon {
  margin-right: 14px !important;
  align-self: center;
  flex: none;
  color: rgba($primary, 0.85);

  &.hasMultipleOptions {
    align-self: flex-start;
    margin-top: 19px;
  }
}

.formIconAlignTop {
  align-self: flex-start;
  margin-top: 8px;
}