@import 'Colors.scss';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

// duplicate
.list {
  background: white;
  flex: 1;
  overflow-y: auto;

  > div {
    padding-right: 12px;
    //border-bottom: 1px solid $border;
    cursor: pointer;
  }
}

// duplicate
.hoverToolsRight {
  position: absolute;
}

.hoverToolsRight {
  visibility: hidden;
  top: 0;
  right: 5px;
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  //background: rgba(255, 255, 255, 0.8);
}

.listItem {
  &.isTouchDevice {
    -webkit-user-drag: none;
  }

  position: relative;

  &.isEditing {
    z-index: 2;
  }
}

@media (hover: hover) {
  .listItem:hover .hoverToolsRight {
    visibility: visible;
  }
}
