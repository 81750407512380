.root {
  &.withTransition {
    transition: opacity 1s;
  }

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
  overflow: hidden;
  background-color: white;

  &.isTranslucent {
    background-color: rgba(7, 17, 62, 0.75);
    color: white;
  }

  &.isVisible {
    opacity: 1;
    pointer-events: all;
  }

  &.isHidden {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes spinner {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

img.progress {
  animation: spinner 4s linear infinite;
}

.progress {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

.linearProgress {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
}

.message {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  font-size: 15px;
}
