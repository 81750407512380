@import "Constants.scss";

.dashboard-top {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.mat-button {
  -webkit-font-smoothing: antialiased;
  color: white !important;
  text-transform: none !important;
}

#create-first-project #no-project-img, #create-first-team #no-project-img {
  height: 380px;

  @media(max-width: $vertical-phone-max-width) {
    height: 300px;
    margin-top: 60px;
  }
}

h1.dashboard-title {
  flex: 1;
  font-size: 36px;
  font-weight: 300;
}

#create-first-project h2, #create-first-team h2 {
  font-weight: 400;
  font-size: 30px;
}

#create-first-project, #create-first-team {
  position: relative;
  margin: 50px auto;
  margin-top: -25px;
  text-align: center;
  max-width: 400px;
}

#create-first-project #tut-arrow, #create-first-team #tut-arrow {
  position: absolute;
  right: -50px;
  top: 390px;
}
