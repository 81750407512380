.root.isEditable {
  margin-bottom: 20px;
  min-width: 250px !important;
}

.input {
  font-size: 1.6em !important;
  font-weight: 400;
  text-transform: uppercase;
}

.root:not(.isEditable) {
  text-transform: uppercase;
  font-weight: 200;
  font-size: calc(var(--report-font-size-scale) * 34px);
  letter-spacing: 0.5px;
  margin-bottom: 29px;
}
