@import 'Colors.scss';
@import 'Constants.scss';

.root {
  background-color: $gray11;

  @media(max-width: $vertical-phone-max-width) {
    order: 1;
  }
}

.nav {
  flex-direction: column;
  height: auto !important;
  background-color: transparent !important;
  width: 80px !important;

  button {
    padding: 10px 0 !important;

    @media(max-width: $vertical-phone-max-width) {
      padding: 12px 6px !important;
      min-width: 50px !important;

      :global(.MuiBottomNavigationAction-label) {
        font-size:11px
      }

      :global(.MuiBottomNavigationAction-label.Mui-selected) {
        font-size:11px
      }
    }
  }

  >div {
    order: 5;
  }

  :global(#DrawToolTypeSelect) {
    order: 0;
  }

  @media(max-width: $vertical-phone-max-width) {
    :global(#DrawToolTypeBackgroundImage) {
    //  order: 1;
    }
  }

  @media(max-width: $vertical-phone-max-width) {
    flex-direction: row;
    width: auto !important;
    justify-content: flex-start;
    overflow-x: auto;
  }
}