@import 'Colors.scss';

.foreignContainer {
  pointer-events: auto;
  padding: 10px;
  background-color: white;
  text-align: center;
  > div {
    margin: 15px;
  }

  button {
    margin: 5px 0;
  }
}

.cropRectangle {
  fill: rgba($primary, 0.4);
}