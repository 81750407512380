@import "node_modules/reset-css/sass/_reset.scss";
@import "Colors.scss";
@import "Constants.scss";
@import "node_modules/@progress/kendo-theme-material/scss/treeview";

@font-face {
  font-family: "renogareregular";
  src: url("/fonts/renogare-regular-webfont.woff2") format("woff2"),
    url("/fonts/renogare-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

#header {}

a {
  text-decoration: none;
}

b {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  background: white;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1;
  user-select: none;
  overflow: hidden;
  touch-action: manipulation;
}

#app {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  /* iOS Safari :( */
  display: flex;
  flex-direction: column;


  &.isLoggedOut {
    height: auto;
    position: relative;
    min-height: 100vh;
  }
}

#content {
  flex: 1 1 auto;
  display: flex;
  min-height: 0; // chrome 72 bug https://www.reddit.com/r/webdev/comments/alg8az/anyone_experiencing_issues_with_flexbox_and/

  >* {
    width: 100vw;
    height: inherit;
    flex: 1 1 auto;
    justify-content: center;
  }
}

kendo-pdf-page {
  // can cause problem writing in the margins of the document by mistake
  // but needed for background bleeds
  overflow: visible;

  >div {
    overflow: visible;
  }
}

*[hidden] {
  display: none !important;
}

em {
  font-style: italic;
}

div[role="tooltip"] {
  position: fixed;
  z-index: 9999;
}

// hack
.isShapeHidden {
  visibility: hidden;
}

.ShapePreview .isShapeHidden {
  visibility: visible;
}

#svgTag .isDimensionHidden {
  // cant use visibility hidden because of text white background filter that will still be visible
  //visibility: hidden;
  display: none;
}

.reflex-container>.reflex-splitter.reflex-thin {
  opacity: 1;
}

.reflex-splitter {
  overflow: visible;
  background-color: $secondary !important;
  border-style: none !important;
}

.reflex-container.horizontal {
  >.reflex-splitter {
    height: 20px;
    margin-bottom: 0px !important;
    border-top: 1px solid $secondary !important;
    background-color: $gray11 !important;

    @media (hover: hover) {
      background-color: $secondary !important;
      border-style: none !important;
      height: 10px !important;
      margin-bottom: -5px !important;

      &:hover {
        background-color: $gray13 !important;
      }
    }

    &:active {
      background-color: $gray13 !important;
    }
  }
}

.reflex-container.vertical {
  >.reflex-splitter {
    border-left: 1px solid $secondary !important;
    border-right: 1px solid $secondary !important;
    background-color: $gray11 !important;
    width: 15px !important;

    @media (hover: hover) {
      background-color: $secondary !important;
      border-style: none !important;
      width: 5px !important;

      &:hover {
        background-color: $gray13 !important;
      }
    }

    &:active {
      background-color: $gray13 !important;
    }
  }
}

// undo default material-ui bad settings
.MuiTab-labelIcon .MuiTab-wrapper>*:first-child {
  margin-bottom: 0 !important;
  margin-right: 6px;
}

.MuiInputBase-root.Mui-focused {
  color: black;
}

.MuiDialog-container {
  height: 100dvh !important;
}

.MuiBottomNavigationAction-label.Mui-selected,
.MuiTab-root.Mui-selected {
  background-color: transparent !important;
  color: $primary;
}

.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 13px;
  font-weight: 500;
}

// workaround for edit mode that's taller than regular row
.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.js-image-zoom__zoomed-image {
  z-index: 3;
}

button * {
  pointer-events: none;
}

.reflex-container > .reflex-element {
  scrollbar-width: thin;
}

.reflex-container.reflex-resizing>.reflex-element {
  pointer-events: inherit;
  -webkit-user-select: inherit;
  -moz-user-select: inherit;
  -ms-user-select: inherit;
  user-select: inherit;
}

button[aria-label="Unzoom image"] {
  background-image: url(/images/close.svg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 48px;
}

div[data-rmiz-wrap="visible"],
div[data-rmiz-wrap="hidden"] {
  width: 100%;
  height: 100%;
  display: block;
  visibility: visible;
}

.mdl-card {
  box-shadow: none !important;
  margin: -24px !important;
}

.firebaseui-container {
  max-width: 600px !important;
}

@media (min-width: $vertical-phone-max-width+1) {
  :global(.intercom-app)>div {
    bottom: auto;
    top: 60px !important;
  }
}

#draggable-dialog-title {
  cursor: move;
}

.pac-container {
  //this is a fix for google autocomplete not showing up
  background-color: #fff;
  z-index: 2000;
  position: fixed;
  display: inline-block;
}

.firebaseui-idp-list {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  li:nth-of-type(1) {
    order: 2
  }

  li:nth-of-type(2) {
    order: 1
  }

  li:nth-of-type(3) {
    order: 0
  }
}

.MuiListItemAvatar-root {
  min-width: auto;
  flex-shrink: 0;
  position: relative;
}

.MuiAvatar-root {
  //width: 40px;
  //height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.MuiAvatar-colorDefault {
  color: #eeeef1;
  background-color: #fafafb;
}

.MuiListItemText-root {
  flex: 1 1 auto;
  padding: 0 12px;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.MuiListItemText-multiline {
  margin-top: 6px;
  margin-bottom: 6px;
}

.MuiTypography-root {
  margin: 0;
  user-select: none;
}

.MuiTypography-body1 {
  color: black;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.MuiTypography-body2 {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.54);
}

.MuiTypography-displayBlock {
  display: block;
}

.MuiTypography-displayBlock {
  display: block;
}



// mobile stuff
@media(min-width: ($horizontal-phone-max-width+1)) {
  .visible-phone {
    display: none
  }
}

@media(max-width: $horizontal-phone-max-width) {
  .hidden-phone {
    display: none !important;
  }
}

@media(max-height: $horizontal-phone-max-height) {
  .hidden-horizontal-phone {
    display: none;
  }
}

@media(min-height: ($horizontal-phone-max-height+1)) {
  .visible-horizontal-phone {
    display: none
  }
}

@media(max-width: $vertical-phone-max-width) {
  .hidden-vertical-phone {
    display: none;
  }
}

@media(min-width: ($vertical-phone-max-width+1)) {
  .visible-vertical-phone {
    display: none
  }
}