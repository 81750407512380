@import 'Colors.scss';

.CountPointComponent {
    &.isHidden {
        visibility: hidden;
    }

    >use {
        fill: currentColor;
        fill-opacity: 0.5;
    }

    &.shouldHover {
        transition: filter 0.3s;

        &:hover {
            filter: brightness(0.8);
        }
    }
}

.nodeIsSelected .CountPointComponent>use {
    stroke: currentColor;
    fill-opacity: 0.9;
}