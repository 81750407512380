 $primary: #07113e; //rgb(24, 39, 53);
 $secondary: #eeeef1; //#eff0f2;// #f6f6f8; //#2b3e61;
 $action:#05c0ef; //#04d0bc; //#25c2ca; //
 $gray1: #f0f0f1;
 $gray2: rgba(0,0,105,0.04);
 $gray3: #808085; //365271
 $gray4: rgba($secondary, 0.2); // #f0f0f0;
 $gray5: rgba(0, 0, 0, 0.54);
 $gray6: #fefeff;
 $gray7: black; // text
 $gray8: rgba(0,0,0,0.08);
 $gray9: #e7e8ea;
 $gray10: rgba(#f0f0f2, 0.5);
 $gray11: #fafafb; //#f3f3f5;//#f9f9fa;
 $gray12: rgba($gray11, 0.8);

 $gray13: #e2e3e7;
 $gray14: #f9f9fa;
 $gray15: rgba(2,28,51,0.46);
 $gray16: rgba(19, 62, 106, 0.051);
 $gray17: #f8f8f9;
 $gray18: #dedee4;

 $red: #cc0404;
 $green: #1ab80b;
 $orange: #f88024;

 $highlight: #bceafa; //rgba($action, 0.34);
 $border: $gray1;
 $darkBorder: $gray15;
 $text: rgba(0,0,0,1);
 $body: white;


 // make available to JS
 :export {
  primary: $primary;
  secondary: $secondary;
  action: $action;
  border: $border;
  darkBorder: $darkBorder;
  text: $text;
  gray1: $gray1;
  gray2: $gray2;
  gray3: $gray3;
  gray4: $gray4;
  gray5: $gray5;
  gray6: $gray6;
  gray7: $gray7;
  gray11: $gray11;
  gray14: $gray14;
  gray15: $gray15;
  gray16: $gray16;
  gray18: $gray18;
  highlight: $highlight;
  red: $red;
  green: $green;
  orange: $orange;
}