@import "Colors.scss";

.root {
  background: $gray16;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 0;
  margin-bottom: 5px;

  @media (hover: hover) {
    &:hover {
      background-color: $secondary;
    }
  }

  td {
    padding: 0 !important;

    > div > div {
      background-color: transparent !important;
    }

    input {
      padding: 7px 16px;
    }
  }
}
