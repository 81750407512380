@import "Colors.scss";

.root {
  width: 500px;
  max-height: 65vh;
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message {
  margin-top: 55px;
  font-size: 18px;
  color: $text;
  display: flex;

  > * {
    margin-right: 5px;
    display: block;
  }
}
