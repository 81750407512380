@import "Colors.scss";

.root {
  min-width: 0;
  display: flex;
  flex: 1;
  align-items: center;

  // header text
  .title {
    display: flex;
    align-items: center;
    margin-left: 0px;
    transition: margin-left 0.1s linear 0.1s;
    flex: 1;
    min-width: 0;

    > svg {
      margin-right: 10px;
    }

    > div {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.hasMeasurements:hover,
  &.hasSelection {
    .title,
    :global(.Mui-checked) + div {
      margin-left: 30px;
    }
    .itemToggle {
      opacity: 1;
    }
  }
}

.icon {
  color: $action;
}

.itemToggle {
  position: absolute;
  left: 14px;
  opacity: 0;
  left: 2px;
  transition: opacity 0.1s linear 0.2s;

  &:global(.Mui-checked) {
    opacity: 1;
  }
}

.buttons {
  background-color: $secondary;
  display: flex;

  button {
    color: $action !important;
    font-size: 12px !important;
    text-transform: none !important;

    svg {
      // margin-right: 5px !important;
    }
  }

  .cancelButton {
    color: $text !important;
  }
}




.moreButton button {
  color: $text !important;
}
