/* ALSO CHECK WorksheetsTree.modules.scss */

@import "Colors.scss";

/* overrides CSS IN JS in antd tree */
@media(hover:none) {
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner,
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
    border-color: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled) .ant-tree-checkbox-inner {
    background-color: inherit;
    border-color: inherit;
  }


  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):after {
    border-color: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox-wrapper-checked:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner,
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
    background-color: inherit;
    border-color: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
    outline: none;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
    background: inherit;
  }

  .ant-tree-treenode:hover :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
    opacity: 1;
  }


  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-switcher:not(.ant-tree-switcher-noop):hover {
    background-color: inherit;
  }


  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-node-content-wrapper:hover,
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-checkbox+span:hover {
    background-color: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree .ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
    background-color: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
    background: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
    background: inherit;
  }

  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
  :where(.css-dev-only-do-not-override-1uq9j6g).ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: inherit
  }
}
/* end original antd tree css */


.ant-tree-list-holder-inner {
  align-items: stretch !important;
}

.ant-tree-node-content-wrapper {
  flex: 1;
  margin-left: -4px !important;
  min-width: 0;

  &.ant-tree-node-selected {
    background-color: transparent !important;
  }

  @media(hover:hover) {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.ant-tree-list-holder-inner>div:first-of-type .ant-tree-node-content-wrapper {
  border-radius: 0 !important;
}

.ant-tree-list-holder-inner>div .ant-tree-node-content-wrapper {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-tree-switcher-leaf-line {
  &::before {
    inset-inline-end: 14px !important;
  }

  &::after {
    height: 21px !important
  }
}

.ant-tree-show-line {
  .ant-tree-indent-unit:before {
    inset-inline-end: 14px !important;
  }

  .ant-tree-indent-unit {
    width: 30px !important;
  }

  .ant-tree-switcher {
    width: 30px !important;
  }

  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
    height: 21px !important;
  }
}

.ant-tree-list-holder {
  margin-left: -25px;
  overflow-y: inherit !important;
  overflow-anchor: inherit !important;
}

.ant-tree-draggable-icon {
  display: none;
}

.ant-tree-switcher {
  color: $gray3;

  display: flex;
  align-items: center;
  justify-content: center;

  >svg {
    transition: transform 0.2s;
  }
}

.ant-tree-switcher_close>svg {
  transform: rotate(-90deg);
}

.ant-tree .ant-tree-treenode {
  padding-bottom: 0 !important;

  &.dragging .ant-tree-title>div::before {
    outline: 1px solid $highlight;
  }
}

.ant-tree-drop-indicator {
  background-color: $action !important;

  &::after {
    border-color: $action !important;
  }
}

.ant-tree-treenode .ant-tree-drop-indicator {
  margin-left: -5px;
  margin-bottom: 2px;
}

.ant-tree-treenode.drop-target.drag-over:not(.ant-tree-treenode-switcher-open) .ant-tree-drop-indicator {
  margin-left: 17px;

  &::after {
    border-radius: 0 !important;
    width: 0 !important;
    inset-inline-start: -2px !important;
    top: -6px !important;
    border-top-style: none !important;
    border-right-style: none !important;
    border-bottom-style: none !important;
  }
}

.e-treeview.e-fullrow-wrap .e-text-content {
  pointer-events: all !important;
  display: flex;
}

.e-list-text {
  flex: 1;
}

.m-tree {
  padding-left: 28px;
}

.m-node .inner {
  height: 40px;
  position: static !important;
  display: flex;
  align-items: center;

  &>div {
    flex: 1;
  }
}

.m-node .children {
  //padding-left: 25px !important;
}

.m-node .collapse {
  position: static !important;
  margin-right: 10px;
  margin-left: -34px;
  opacity: 0.6;
  z-index: 10;
  transition: transform 0.2s;
  display: block;
  width: 24px;
  height: 24px;
  left: -15px !important;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve" height="24px" width="24px"><g><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></g></svg>');

  &::before {
    content: '' !important;
  }
}

.caret-down {
  transform: rotate(90deg);
}