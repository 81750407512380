@import "Colors.scss";

.root {
  padding: 0 8px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.title {
  display: flex;
  align-items: center;
  font-size: 13px;
  letter-spacing: normal;
  color: $text;

  > svg {
    margin-right: 4px;
  }
}

.dimensions {
  letter-spacing: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > div {
    padding: 8px;
  }
}


.by {
  padding: 0 !important;
  font-size: 19px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: calc(50% - 11px);
  left: calc(50% - 5px);
}

.buttons {
  button {
    margin: 0 10px;
  }
}