.title {
  display: flex;
  align-items: center;
  margin-right: 25px;

  >div {
    margin-left: 10px;
  }

  .toolName {
    font-weight: bold;
  }
}

.manualScaleLink {
  margin-top: 6px;

  a {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
}