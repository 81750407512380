@import 'Colors.scss';

.container {
  display: flex;
  align-items: center;
}

/* duplicate */
.isDragging {
  pointer-events: none;
  //justify-content: center;
  //border: 2px dashed $action !important;
 // padding: 5px;
 // margin: -7px;
  //font-weight: normal;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.enterQuantityLink {
  font-size: 13px;
  color: $action;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 14px;
}