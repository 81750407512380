@import 'Colors.scss';

.root {
  position: relative;
}

.minusButton, .plusButton {
  position: absolute;
  bottom: -2px;
  color: $primary;
}

.minusButton {
  left: 0;
}

.plusButton {
  right: 5px; // linked to margin unfortunately which depends on parent component
}

.integerField input {
  //padding: 8px 28px 6px;
  text-align: center;
}