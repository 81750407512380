@import "Colors.scss";

.root {
  display: flex;
  justify-content: flex-end;
  text-align: right;

  &.shouldPageBleed {
    margin-right: -80px;
  }

  margin-top: 30px;
  margin-bottom: 60px;

  td {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .lastTax {
    td {
      border-bottom: none;
    }
  }

  td:last-child {
    border-bottom: none;
  }

  .label {
    padding-right: 30px;
    padding-left: 10px;
  }

  .value {
    padding-right: 5px;
  }

  .taxNumber {
    font-size: calc(var(--report-font-size-scale) * 12.8px);
  }

  .underline td {
    font-family: MontserratBold;
    font-weight: 700;
    border-bottom: 1px solid $primary;
  }
}

.grandTotal {
  color: var(--logo-color-text);
  font-weight: bold;
  font-family: MontserratBold;

  td {
    border-bottom: none;
  }

  td:last-child {
    width: 5px;
  }

  td:first-child {
    padding-left: 20px;
  }
}

.shouldPageBleed .grandTotal td:last-child {
  width: 80px;
}
