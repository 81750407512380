@import "Colors.scss";

.root {
  min-height: 85vh !important; /* somehow needed as well as height */
  height: 85vh;
  width: 85vw;
  border-bottom: 1px solid #f0f0f1;
  // min-width: 400px;
  display: flex;
  flex-direction: column;
}

.progress {
  position: absolute;
  left: 50%;
  top: 50%;
}

// duplicate
.title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 6px 14px !important;
  background: $primary;
  color: white !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .isEmptyTitle {
    label:not([data-shrink="true"]) {
      color: white !important;
      font-size: 24px;
    }
  }
}

.content {
  min-height: 1px;
  flex: 1;
  display: flex;
}

.column {
  width: 500px;

  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 10px;
  }
}

.column2 {
  width: 800px;
  padding: 15px;

  z-index: 2;
  margin: -15px -15px 0 0;
  background: $gray17;
  position: relative;
}

.textField {
  flex: 1;
}

.nameField,
.categoryCombo {
  flex: 1;
  margin-left: 10px !important;
  margin-right: 10px !important;

  label {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  input {
    color: white;
  }
}

.nameField > div,
.categoryCombo > div > div {
  background-color: #ffffff14 !important;
  font-weight: 500;
  font-size: 20px !important;
}

.categoryCombo {
  flex: none;
  display: flex;

  > div {
    margin: 0;
  }

  :global(.MuiFilledInput-input) {
    padding: 8px 7px;
    span {
      color: white;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.descriptionField {
  width: 100%;
  flex: 1;

  > div {
    flex: 1;
    font-size: 14px !important;
  }

  textarea {
    height: 100% !important;
  }
}

// duplicate
.imageBox {
  // height defined twice?
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: $secondary;
    width: 50px;
    height: 50px;
  }
}

.placeHolderImage {
  width: 100%;
  height: 100%;
}

.measurements,
.tasks {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.info {
}

// Proper flex 1 to editor to fill remaining space of info widget
.notes,
.notes :global(.Draftail-Editor),
.notes :global(.DraftEditor-root),
.notes :global(.DraftEditor-editorContainer) {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.descriptionField {
  flex: 1;
}
