@import "Colors.scss";
@import "Constants.scss";

.root {
  background: rgb(24, 39, 53) url(/images/bg.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  height: 100vh !important;
  align-items: center;
  color: white;
  justify-content: flex-start !important;

  h3 {
    text-align: center;
    max-width: 305px;
    line-height: 1.3;
    font-size: 14px;
    margin-top: -9px;
    margin-bottom: -1px;
  }

  .bottomText {
    line-height: 1.3;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logo {
  display: flex;
  font-family: "renogareregular";
  color: white;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  font-size: 27px;

  @media(max-width: $vertical-phone-max-width) {
    margin: 10px 0;
  }

  img {
    margin-right: 10px;
    margin-left: -10px;

    @media(max-width: $vertical-phone-max-width) {
      width: 50px;
    }
  }
}

.phone, .email {
  color: $action !important;
}


.terms {
  font-size: 12px;
  color: $gray3;
  padding: 20px;
  padding-bottom: 50px;

  a {
    color: $gray3 !important;
    text-decoration: underline;
  }
}