@import 'Colors.scss';

.root {

}

.title {
  display: flex;
  align-items: center;
  flex: 1;

  > div {
    margin-left: 10px;
  }
}

.buttons {
  margin-right: 5px;
}

.button {
  color: $action !important;
  font-size: 12px !important;
  text-transform: none !important;

  svg {
    //margin-right: 5px !important;
  }
}

