@import 'Colors.scss';
@import 'Constants.scss';

.designSectionSeparator {
  width: 100%;
  height: 20px;
  position: relative;
  margin-top: -34px;
  margin-bottom: 26px;

  &::before, &::after {
    content: '';
    background: linear-gradient(-45deg,#d0d0d1 16px, transparent 0), linear-gradient(45deg, #d0d0d1 16px, transparent 0);
    background-repeat: repeat-x;
    background-size: 32px 32px;
    display: block;
    position: absolute;
    bottom: -5px;
    height: 32px;
    left: 11px;
    right: 11px;

    @media(max-width: $vertical-phone-max-width) {
      left: 0px;
      right: 0px;    
    }
  }

  &::after {
    bottom: -6px;
    background: linear-gradient(-45deg, $gray14 16px, transparent 0), linear-gradient(45deg, $gray14 16px, transparent 0);
    background-repeat: repeat-x;
    background-size: 32px 32px;
  }

  &.reverse{
    transform: rotate(180deg);
    margin-top: 12px;
    margin-bottom: -34px;
    z-index: 1;
  }

  &.isHiddenSection {
    visibility: hidden;

    & + .designSectionSeparator {
      visibility: hidden;
      height: 0;
    }
  }
}

.designSectionHeader {
  color: #616161;
  font-size: 14px;
  padding: 24px 14px 9px 14px;
}

.paper {
  display: flex;
  flex-direction: column;

  @media (max-width: $vertical-phone-max-width) {
    margin: 15px 0 !important;
    padding: 55px 5px !important;
  }
}