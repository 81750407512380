@import 'Colors.scss';


.root > div {
  background-color: $primary;
}

.title {
  font-weight: bold;
  color: white;
}

.checkbox span {
  color: white;
}

.changesLink {
  color: $gray9;

  &:hover {
    text-decoration: underline;
  }
}

.closeButton {
  color: $gray11;
  margin-left: 10px;
}

.actionButton {
  margin-left: 10px;
}