@import 'Colors.scss';

.root {
  position: absolute;
  opacity: 1;
  background-color: $secondary;
  background-blend-mode: multiply;
}

.icon {
  text-transform: uppercase;
  background-color: $secondary !important;
  color: $gray3 !important;
  margin-left: 2px;
  width: 40px;
  height: 40px;
}