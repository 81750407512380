@import 'Colors.scss';

.root {

}

.row {
  flex: 1;
  display: flex;
  max-width: 350px;
  > div {
     flex: 1;
  }
}

.expansionPanel {
  font-size: 13px;
  font-weight: 400;
  color: #9a9a9a;
}

.adjustmentsTitle > div {
  align-items: center;

  span.isActive {
    font-weight: 500;
    color: $text;
  }
}

.expansionPanelContent {
  padding: 0;
  margin-left: 12px;
  margin-bottom: 10px;
}

.tableValueQuantity {
  margin-left: 10px;
}

