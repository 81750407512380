@import "Colors.scss";
@import "Constants.scss";

.root {
  display: flex;
  flex: 1;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.rootEdit {
  background-color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -8px;

  .line1,
  .line2 {
    display: flex;
    width: 100%;
  }
}

.separator {
  flex: 1;
}

.textField {
  margin-top: 0 !important;

  input {
    font-size: 15px !important;
  }
}

.nameField {
  flex: 1;
}

.textFieldNoLabel {
  input {
    padding: 8px 8px 6px;
  }

  label {
    display: none !important;
  }
}

.textField:not(:last-child) {
  margin-right: 5px !important;
  flex: 1;
}

.editTools {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-bottom: 6px;
  margin-top: -3px;
  margin-right: 3px;

  >* {
    margin-right: 5px;
  }
}

.detailsButton {
  color: $primary !important;
  //color: $action !important;
}

.acceptButtonIcon {
  color: $green !important;
}

.cancelButtonIcon {
  color: $red !important;
}

.acceptButton {
  //color: $action !important;
  background-color: $primary !important;
}

.cancelButton {
  //color: $red !important;
}

.detailsSeparator {
  width: 10px;
}

.flexSeparator {
  flex: 1;
}

.providing {
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 8px;
  margin-left: 7px;
}

.avatarIcon {
  color: $gray3;

  &.isLabour.hasParentTask {
    opacity: 0.8;
  }
}

.isReportView .avatarIcon.isLabour.hasParentTask,
.avatarIcon.isLabour.hasParentTask:not(.hasCategThumb) {
  opacity: 0 !important;
}

.labourIconSvg {
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 1;
  left: 50%;
  top: 50%;
  color: $gray3;
  transform: translate(-50%, -50%) scaleX(-1) rotate(5deg) !important;

  &:not(.hasParentTask) {
    width: 18px;
    height: 18px;
    display: none;
  }
}

.labourIcon {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(250,250,251, 0.9); //$gray11;
  border-top-right-radius: 4px;
  padding-left: 7px;
  padding-top: 2px;
  padding-right: 3px;
  padding-bottom: 1px;
  color: /*$primary;*/$gray3;
  opacity: 0.9;

  &.hasParentTask {
    //background-image: url(/images/helmet2.png);
  }

  &:not(.hasParentTask) {
    // not really needed in list
    display: none;
  }
}

.isReportView .labourIcon,
.labourIcon.hasParentTask:not(.hasCategThumb) {
  top:0;
  right: 0;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-self: center;
  opacity: 1;
  
  >img {
    width: 24px !important;
    height: 24px !important;
  }
}

.smallLabourIcon {
  width: 17px;
  height: 17px;
  color: $gray3;
  stroke: $gray3;
  stroke-width: 3px;
  margin-right: 5px;
  opacity: 0.6;
  display: none; // feature disabled for now
}

.materialText>span {
  /* limit wrap lines */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: inherit;
  overflow: hidden;
}

.materialText.hasParentTask {
  @media(max-width:$vertical-phone-max-width) {
    padding: 0 6px;
  }

  >span {
    -webkit-line-clamp: inherit;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media(max-width:$vertical-phone-max-width) {
      font-size: 12px;
    }
  }

  >p {
    text-overflow: ellipsis;
    overflow: hidden;

    @media(max-width:$vertical-phone-max-width) {
      font-size: 12px;
    }
  }
}

.priceTextField {
  flex: 1.5 !important;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.unit {
  font-size: 12px;
  font-weight: 400;
  margin-top: -1px;
  line-height: 1;
  color: #808085;
  white-space: nowrap;
}

.duplicatedMessage {
  display: flex;
  align-items: center;
}

.isItemHidden {
  opacity: 0.4;
}

.verifiedIcon {
  color: $action;
  width: 19px;
  height: 13px;
}