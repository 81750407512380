@import "Colors.scss";
@import "Constants.scss";

// BADDDDD DUPLICAtE

.root {
  min-width: 400px;
  display: flex;
  flex-direction: column;

  :global(.MuiFilledInput-root.Mui-disabled),
  :global(.MuiFormLabel-root.Mui-disabled),
  :global(.MuiInputBase-root.Mui-disabled),
  :global(.Mui-disabled) {
    background-color: transparent !important;
    opacity: 1;
  }

  &.isReadonly {
    .categoryCombo {
      pointer-events: all !important;
      user-select: all !important;

      button {
        visibility: hidden;
      }
    }

    .adjustmentsGroupTitle {
      color: $text;
      font-size: 14px;
    }

    .adjustmentsGroup>div:not(:first-of-type) {
      color: $text
    }

    select+svg {
      visibility: hidden;
    }

  }
}

// duplicate
.title {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 6px 14px !important;
  background: $primary;
  color: white !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .isEmptyTitle {
    label:not([data-shrink="true"]) {
      color: white !important;
      font-size: 24px;
    }
  }

  @media (max-width: $vertical-phone-max-width) {
    padding: 6px 0px !important;
  }
}

.nameField,
.categoryCombo {
  flex: 1;
  margin-left: 10px !important;
  margin-right: 10px !important;

  label {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  input {
    color: white;
  }
}

.nameField>div,
.categoryCombo>div>div {
  background-color: #ffffff14 !important;
  font-weight: 500;
  font-size: 20px !important;

  @media (max-width: $vertical-phone-max-width) {
    font-size: 16px !important;
  }
}

.categoryCombo {
  max-width: 25%;
  flex: none;
  display: flex;

  @media (max-width: $vertical-phone-max-width) {
    flex: 1;
    margin-left: 0 !important;
  }

  >div {
    margin: 0;
  }

  :global(.MuiFilledInput-input) {
    padding: 8px 7px;

    span {
      color: white;
      font-size: 20px;
      font-weight: 500;

      @media (max-width: $vertical-phone-max-width) {
        font-size: 16px;
      }
    }
  }
}

.content {
  flex: 1;
  display: flex;
  padding: 18px;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: $vertical-phone-max-width) {
    flex-direction: column;
  }
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-right: 18px;
  }
}

.column2 {
  z-index: 2;
  margin: -15px -15px 0 0;
  background: $gray17;
  position: relative;

  @media (max-width: $vertical-phone-max-width) {
    margin: 0;
  }
}

.nameField {
  flex: 1;
  margin-left: 10px !important;
  margin-right: 10px !important;

  @media (max-width: $vertical-phone-max-width) {
    flex: 2;
  }
}

.moreButton {
  color: white !important;
  margin-right: -11px !important;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;

  >svg {
    color: $secondary;
    width: 50px;
    height: 50px;
  }
}

.tabs {
  flex: none !important;
  background-color: $secondary !important;
  z-index: 1;
}

.tab {
  flex-basis: 0;
}

.tabContent {
  padding: 18px;
  overflow-x: hidden;
  z-index: 1;
  background: $gray17;
  flex: 1;
}

.unitComboBox {
  width: 100%;
}

.formRow {
  display: flex;
  margin-bottom: 8px;

  >* {
    flex: 1;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.expansionPanel {
  font-size: 13px;
  font-weight: 400;
  color: #9a9a9a;
}

.adjustmentsGroup {
  background: #edeef1;
  padding: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.adjustmentsGroupTitle {
  font-size: 12px;
  margin: 10px 7px;
  color: #6d6d6f;
  letter-spacing: 0.00938em;
  font-weight: 400;
}

.adjustmentsTitle>div {
  align-items: center;
}

.expansionPanelContent {
  padding: 0;
  margin-left: 0px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  .unitComboBox {
    flex: inherit;
    width: auto;
  }
}

.textFieldResult {
  font-weight: 500;
  font-size: 18px;
}

.descriptionField {
  margin-bottom: 15px;
}

.acceptButtonIcon {
  color: $green !important;
}

.cancelButtonIcon {
  color: $red !important;
}

.formulaContainer {
  position: relative;
  overflow-x: hidden;
}

.formulaField {
  margin-bottom: 0 !important;

  &.isDisabled textarea {
    pointer-events: all;
    user-select: all;
  }

  >div {
    transition: min-height 0.2s;
    min-height: 58px;
    align-items: flex-start;
  }

  &.isDraggingDimension {
    // pointer-events: none; // counter intuitive but needed
    border: 2px dashed $action;

    >* {
      pointer-events: none;
    }
  }

  &.isDraggingDimensionOver {
    >* {
      pointer-events: none;
    }

    // border-width: 5px !important;
    background: rgba($action, 0.5);
  }

  &.isEditingFormula {
    >label {
      color: $action !important;
    }

    >div {
      min-height: 130px !important;
      background-color: #11316a1f !important;

      &::after {
        transform: scaleX(1);
      }
    }

    textarea {
      min-height: 100px;
    }
  }
}

.formulaAcceptButtons {
  transition: right 0.2s;
  right: -100px;
  position: absolute;
  bottom: 2px;
  background: $gray17;
  padding: 4px;
  border-top-left-radius: 4px;
}

.formulaMathButtons {
  overflow: hidden;
  display: flex;
  align-items: center;
  background: $gray16;
  padding: 0px;
  margin-bottom: 8px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 0;
  transition: height 0.2s;
}

.formulaDimensions {
  position: absolute;
  transition: left 0.2s;
  transition-delay: 0.2s;
  left: 0px;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &Content {
    border-bottom-left-radius: 4px;
    flex: 1;
    background: $gray17;
  }
}

.formulaDimensionsHeader {
  // DUPLICATE
  border-top-left-radius: 4px;
  background-color: $gray17;
  color: $gray3;
  padding: 18px 3px 12px 11px;
  border-bottom: 1px solid $gray11;
  letter-spacing: 0.5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  min-height: 41px;
  justify-content: space-between;
}

.formulaDimensionsContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mathButtonIcon {
  width: 38px;
  height: 38px;

  svg {
    width: 21px;
    height: 21px;
  }

  color: $gray3 !important;
}

.isEditingFormula {
  .formulaAcceptButtons {
    right: 0px;
  }

  .formulaMathButtons {
    height: 38px;
  }

  .formulaDimensions {
    left: -350px;
  }
}

// duplicate
.enterQuantityLink {
  margin-top: 10px;

  font-size: 13px;
  //color: $action;
  opacity: 0.7;
  text-decoration: underline;
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
}

.label {
  font-size: 12px;
  margin-top: 12px;
  margin-bottom: -9px;
  display: block;
}

.colorButtonContainer {
  display: flex;
  align-items: center;
  margin-right: 6px;

  .colorButton {
    margin-right: -4px;
  }
}