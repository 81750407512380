
.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.subheader {
  font-family: Roboto;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 11px;
  font-weight: 500;
}