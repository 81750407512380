@import "Colors.scss";

.root {
  overflow-x: hidden !important;
  overflow-y: auto;
  scrollbar-width: thin;

  :global(.dragHandle) {
    visibility: visible;
    color: $gray3;
    background: rgba(255, 255, 255, 0.8);
    padding-bottom: 4px;
    padding-right: 13px;
    padding-left: 2px;
  }

  .row>div {
    padding: 4px 25px 4px 10px;
  }

  &.hidePopupMenu .row>div {
    padding-right: 18px;
  }
}

.listContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 1px;
  position: relative;
  overflow: hidden;
}

.orderableItem {
  padding: 2px 0 !important;
}

.row {
  position: relative;
  //cursor: grab;
  cursor: pointer;
  display: flex;
  overflow: visible;
  align-items: center;
  padding: 0 !important;
  flex-shrink: 0;

  >*:first-child {
    flex: 1;
  }

  span {
    line-height: 1.3;
  }
}

.row:hover {
  background: $gray11;
}

.row:active {
  cursor: grabbing;
}

.infoButton {
  color: $gray7 !important;
}

.deleteButton {
  visibility: hidden;
  position: absolute !important;
  right: 2px;
}

.itemToggle {
  position: absolute;
  left: 8px;
  opacity: 0.01;
  width: 37px;
  height: 37px;

  background-color: $gray11;

  @media (hover: hover) {
    &:hover {
      background-color: $gray11;
    }
  }

  &:global(.Mui-checked) {
    opacity: 1;
  }
}

.colorButton {
  display: flex;
  padding: 3px 0 !important;
}

// last child hack
.menuButton {
  position: absolute;
  right: 5px;
  opacity: 0.01;
  background-color: $gray12;
}

@media (hover: hover) {
  .row:hover {
    .deleteButton,
    .itemToggle,
    .menuButton {
      opacity: 1;
    }
  }
}

.row:hover {
  .deleteButton,
  .menuButton {
    opacity: 1;
  }
}

.listSubHeader {
  padding-left: 11px !important;
  padding-bottom: 2px !important;
  padding-right: 16px !important;
  margin-left: 0 !important;
  align-items: center;

  // header text
  span:not(:first-child) {
    margin-left: 0px;
    transition: margin-left 0.1s linear 0.1s;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  >span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
    line-height: normal;
  }

  &:hover span:not(:first-child),
  :global(.Mui-checked)+span {
    margin-left: 30px;
  }

  .itemToggle {
    left: 2px;
    transition: opacity 0.1s linear 0.2s;
  }
}

.listSubHeaderCateg {
  align-items: flex-end;

  .itemToggle {
    bottom: -6px;
  }

  //padding-top: 10px !important;
}

.listSubHeaderSubcateg {
  padding-top: 10px !important;
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

.subsubcateg {
  cursor: auto;
}

.listSubHeaderInactive {
  background-color: $gray11;
}

.checkbox {
  margin: -3px 0 0 -7px !important;
}