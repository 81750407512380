@import "Colors.scss";

.WallComponent {
  color: #cfcfcf; // #9cd6e4;

  .outline {
    stroke-width: 1.5px;
    stroke: currentColor;
    stroke-opacity: 0.5;
  }

  .fill {
    fill: currentColor;
    fill-opacity: 0.5;
  }

  .offsetPath {
    fill: currentColor;
    fill-opacity: 0.3;
  }

  .isHidden {
    visibility: hidden;
  }

  &.isHighlightedFromMeasurement {
    filter: brightness(1.2);
  }
}

.nodeIsSelected .WallComponent {
  color: $action;

  .outline {
    stroke-opacity: 1;
    stroke-width: 3px;
  }

  .fill {
    fill-opacity: 0.9;
  }

  .offsetPath {
    stroke-width: 2px;
    stroke: rgba(7, 18, 61, 0.25);
    stroke-dasharray: 16;

    fill: currentColor;
    fill-opacity: 0.7;
  }
}

@media (hover: hover) {
  #svgTag.shouldHover .WallComponent:hover {
    >g path {
      transition: color 0.2s, filter 0.2s;
    }

    &:hover {
      >g path {
        filter: brightness(0.8);
      }

      text,
      .DimensionLine {
        display: block;
      }
    }
  }
}