
.root {

}


.addButton {
  display: flex;
  justify-content: center;
  flex: 1;
}