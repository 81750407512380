.root {
  //background-color: white;
  width: calc(100% - 60px);

  &::before {
      right: -12px;
  }

  >div {
      padding: 8px 32px 6px 8px;
      background-color: white !important;
  }

  input { 
    padding: 0 !important;
  }

  label {
      display: none !important;
  }
}

.isRootNode {
  margin-left: 10px;
}