@import 'Colors.scss';
@import "Constants.scss";

.root {
  width: 96vw;
  height: 92vh;
  @media(max-width: $vertical-phone-max-width) {
    width: 99vw;
    height: 100vh;
  }
}

.extraButtons {
  margin-left: -15px;
  flex: 1;

  .deleteButton {
    margin-left: 10px;
    color: $red;
  }
}