.root {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-height: 1px;

  > *:first-child {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 1px;
  }
}

.imageHeader {
  top: -64px;
  width: 125px;
  height: 125px;
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  background-color: #1e325012;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  position: absolute;
  border-radius: 100%;
  border: 2px solid white;
  left: 50%;
  transform: translatex(-50%);
  cursor: pointer;
}

.leftButtons {
  display: flex;
  flex: 1;
  align-items: center;
}

.dialogActions {
  flex-wrap: wrap;
}