#ReportView > div.panelGroup > div.panelWrapper:not(:last-of-type) {
  margin-right: -1px;
}

#ReportView .MuiPaper-root {
 // color: black;
}

.kendo-pdf-export {
  //font-family: "DejaVu Sans", "Arial", sans-serif;
  //font-size: 12px;
}

/*
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2018.2.620/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
}
*/

@font-face {
  font-family: "Seaweed Script";
  font-weight: 400;
  font-style: normal;
  src: local('SeaweedScript'), local('SeaweedScript-Regular'), url('/fonts/SeaweedScript-Regular.ttf') format('truetype');
}

@font-face {
  font-family: MontserratLight;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Montserrat Light'), local('Montserrat-Light'), url('/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat'), local('Montserrat-Regular'), url('/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url('/fonts/Montserrat-Italic.ttf') format('truetype');
}

@font-face {
  font-family: MontserratMedium;
  font-style: normal;
  font-weight: 400; // 500
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: MontserratSemiBold;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat Semi-Bold'), local('Montserrat-SemiBold'), url('/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: MontserratBold;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url('/fonts/Montserrat-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('Roboto-Thin'), url('/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('/fonts/Roboto-Bold.ttf') format('truetype');
}
