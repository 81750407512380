@import "Colors.scss";

.root {
  background-color: $gray11;
  padding: 4px 8px 8px 8px;
  display: flex;
  flex-direction: column;

  .avatarContainer {
    padding: 0 10px;
  }

  .avatar {
    background-color: $body;
    width: 50px;
    height: 50px;
    border: 1px solid $gray11;

    >div {
      width: 52px;
      height: 52px;
      opacity: 0.9;
    }
  }

  ul {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
  }

  li {
    //min-width: 100px;
    padding: 0px 2px;

    &:first-child {
      padding-left: 0;
    }

    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    :global(.MuiListItemText-root) {
      padding: 0;
      text-align: center;
    }

    span {
      color: $gray3;
      font-size: 11px;
    }

    svg {
      fill: $primary;
    }

    &.isChecked {
      .avatar {
        border-color: $primary;
      }

      >div:last-child span {
        color: $primary;
        font-weight: 500;
      }
    }

    &.isChecked {

      .checkbox,
      .moreIcon {
        opacity: 1;
      }
    }

    @media(hover: hover) {
      &:hover {

        .checkbox,
        .moreIcon {
          opacity: 1;
        }
      }

      &:hover.isChecked .checkbox {
        background-color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .checkbox,
  .moreIcon {
    opacity: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    transition: opacity 0.3s;
  }

  .checkbox {
    top: -4px;
    left: -4px;
    padding: 28px;
  }

  .moreIcon {
    width: 100%;
    height: 100%;
  }

  &.isHidden {
    visibility: hidden;
    height: 1px;
    flex: none;
    padding: 0;
  }

  .subtitle {
    display: flex;
    align-items: center;
    padding: 0px 0px 8px 2px;
    margin-right: -5px;
    font-size: 13px;
    letter-spacing: 0.4px;
    font-weight: 400;
    color: $gray3;

    >div {
      flex: 1;
    }
  }

  .buttons {
    padding: 6px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .backButton {
    margin-left: -4px;
    margin-right: 7px;
  }

  .name>span {
    display: flex;
    align-items: center;
  }

  .legend {
    width: 5px;
    height: 5px;
    margin-right: 4px;
    flex: none;
  }
}