.root {

}

.dialogContent {
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
}
