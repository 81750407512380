@import "Constants.scss";

.root {
  font-size: calc(var(--report-font-size-scale) * 16px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 74px;
  padding-top: 85px;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &.isEditable {
    padding: 55px;
    padding-top: 55px !important;

    @media(max-width: $vertical-phone-max-width) {
      position: relative;
      padding: 10px;
      padding-top: 40px !important;
      flex-direction: column;
      align-items: flex-start;

      .companyInfo {
        margin-top: 20px;
      }

      .line {
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .textField {
        flex: 1;
        min-width: 130px;
        margin-left: 5px;
        margin-right: 5px !important;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
      }
    }

    .logo { 
      margin-left: 7px;
    }

    .line {
      font-weight: normal;
    }
  }
}

.logo {
  width: 260px;
  height: 100px;
  margin-right: 20px;
  flex: none;

  //hack
  div:first-child>div {
    background-position: bottom left;

    >div {
      opacity: 1;
    }
  }

  &.isPlaceHolder label>div:first-of-type {
    justify-content: flex-start;
  }
}

.icon {
  transform: scale(2);
  transform-origin: left center;
}

.companyInfo {
  text-align: right;
  line-height: 1.5;
  display: flex;
  justify-content: flex-end;

  >div {
    cursor: pointer;
    position: relative;
  }

  .line {
    display: flex;
    justify-content: flex-end;

    >div:not(:last-child) {
      margin-right: 10px;
    }

    .lineItem:not(:last-child)::after {
      content: ' | ';
    }
  }

  .line.bold {
    //font-weight: bold;
    font-weight: 400;
    font-family: MontserratMedium !important;
  }

  .line {
    //font-weight: 300;
    font-family: Montserrat;
  }

  .companyName {
    width: 100%;
  }

  .textField2 {
    width: 100%;


    /* > label {
      left: inherit;
      right: 18px;
    }

    input {
      text-align: right;
    }

    .focusedLabel {
      right: -5px !important;
    }*/
  }
}

.trialWatermark {
  content: '';
  position: absolute;
  top: 100px;
  left: 200px;
  width: 100%;
  height: 1650px;
  opacity: 0.1;
  transform: rotate(-8deg);
  display: flex;
  flex-wrap: wrap;
  font-size: 40px;


  div {
    width: 50%;
    height: 30%;
  }
}