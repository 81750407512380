@import "Colors.scss";

.root {
  height: 97vh;
}

.categorySelect {
  width: 100%;
}

.orderableContainer {
  width: 600px;
  display: flex;
  flex-direction: column;
}

.scrollContainer {
  flex: 1 1;
  min-height: 1px;
  overflow-y: scroll;
  max-height: 70vh;
}

.category {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;

  :global(.dragHandle) {
    visibility: visible;
    color: $gray3;
    position: static;
    margin-right: 7px;
  }

  &.faded {
    opacity: 0.4;
  }
  @media (hover: hover) {
    &:hover {
      background-color: $gray11;

      .deleteButton {
        visibility: visible;
      }
    }
  }
}

.deleteButton {
  position: absolute;
  right: 5px;
  visibility: hidden;
}

.categoryName,
.categoryNameEdit {
  flex: 1;
  align-self: stretch;
  margin: 0 5px;
  z-index: 1;
}

.categoryNameEdit {
  justify-content: space-between;
}

.categoryName {
  @media (hover: hover) {
    &:hover {
      background-color: transparent;
    }
  }

  > div {
    font-weight: 300;

    &.hasItems {
      font-weight: 400;
    }
  }
}
