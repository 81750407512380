@import "Colors.scss";

.createItem {
  font-size: 13px;
  color: $action;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 14px;
}

.createItemButtons {
  margin-top: 5px;
  display: flex;
  margin-left: 15px;
  flex-wrap: wrap;

  button:first-child {
    margin-right: 8px;
  }

  svg {
    margin-right: 8px;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hideDeleteIcon {
  z-index: 1;
  background: white
}
